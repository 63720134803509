<template>
  <div class="mobile-info-bubble-container">
    <div class="title">
      <span class="uds-headers --h3">Avez-vous l'application mobile ?</span>
      <uds-icon class="icon" icon-name="close" size="medium" @click="closeAndShowLater()" />
    </div>

    <div class="mobile-content-info-wrapper">
      <div class="mobile-info-card">
        <div class="content-card">
          Prenez en photo vos reçus pour générer vos notes de frais, créez en quelques secondes vos
          factures, devis et annotez vos opérations bancaires depuis votre smartphone.
        </div>
        <div class="buttons">
          <uds-main-button type="primary" size="small" @click="showAppQrCode()"
            >Découvrir facilement</uds-main-button
          >
          <uds-main-button type="link" @click="closePopup()">Ne plus afficher</uds-main-button>
        </div>
      </div>
      <div>
        <img :src="urlImg" width="180" height="180" loading="lazy" class="img-viewer" >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useModal } from "vue-final-modal";
import { VisualizedInformationState } from "@silexpert/core";
import { filename } from "pathe/utils";
import MobileQrCodeModal from "@/components/layout/MobileQrCodeModal.vue";

const emit = defineEmits(["close"]);

const { getBrandName } = useBrandsComposable();
const dayjs = useDayjs();

const glob = import.meta.glob("@/assets/img/mobileInfo/*.png", { eager: true });
const images = Object.fromEntries(
  Object.entries(glob).map(([key, value]: [string, any]) => [filename(key), value.default]),
);
const brand = getBrandName();

const urlImg = computed<string>(() => images[`${brand.toLowerCase()}_app_info_bubble`]);

const societyStore = useSocietyStore();
const isLoading = ref(false);
const visualizedInformationKey = VisualizedInformationKey.MOBILE_APP_BUBBLE;

function closeAndShowLater() {
  localStorage.setItem(
    "hideMobileAppBubbleDate",
    dayjs().add(1, "day").format("YYYY-MM-DD HH:mm:ss"),
  );

  emit("close");
}

function closePopup() {
  isLoading.value = true;
  try {
    $silex()
      .visualizedInformation.updateOrCreate(societyStore.society?.id ?? 0, {
        key: visualizedInformationKey,
        state: VisualizedInformationState.CANCELED,
      })
      .then(() => {
        societyStore.fetchVisualizedInformations(societyStore.society?.id ?? 0);
      });

    emit("close");
  } catch (error) {
    $notifier().open({ type: "error", content: apiErrorToString(error) });
  } finally {
    isLoading.value = false;
  }
}

function showAppQrCode() {
  isLoading.value = true;
  try {
    $silex()
      .visualizedInformation.updateOrCreate(societyStore.society?.id ?? 0, {
        key: visualizedInformationKey,
        state: VisualizedInformationState.APPROVED,
      })
      .then(() => {
        societyStore.fetchVisualizedInformations(societyStore.society?.id ?? 0);
      });

    const { open, close } = useModal({
      component: MobileQrCodeModal,
      attrs: {
        onClose() {
          close();
        },
      },
    });

    open();
    emit("close");
  } catch (error) {
    $notifier().open({ type: "error", content: apiErrorToString(error) });
  } finally {
    isLoading.value = false;
  }
}
</script>

<style lang="scss" scoped>
.mobile-info-bubble-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 99999;
  bottom: 20px;
  right: 20px;
  width: 570px;
  font-weight: $uds-weight-450;
  font-size: 16px;
  background: $uds-white;
  border-radius: $uds-radius-2;
  border: 1px solid $uds-neutral-500;
  box-shadow: 0px 8px 16px 0px rgba(51, 51, 51, 0.1);
  padding: 20px $uds-spacing-2;

  .title {
    display: flex;
    justify-content: space-between;

    .icon {
      color: $uds-neutral-700;
      cursor: pointer;
    }
  }

  .mobile-content-info-wrapper {
    display: flex;
    gap: $uds-spacing-3;
    .mobile-info-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .content-card {
        margin-top: 15px;
        line-height: $uds-spacing-3;
        color: $uds-neutral-900;
      }

      .buttons {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
}
</style>
