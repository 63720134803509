<template>
  <CommonsModalsModalBody :svg="svg" height="350px">
    <template #title>Contacter le service facturation</template>
    <template #content>
      <uds-input-textarea label="Message :" @input="comment = $event" />
    </template>

    <template #footer>
      <uds-main-button :loading="isLoading" :disabled="!isValid" size="small" @click="contact()"
        >Envoyer</uds-main-button
      >
    </template>
  </CommonsModalsModalBody>
</template>

<script setup lang="ts">
import { Role } from "@silexpert/core";

const emit = defineEmits(["cancel", "close"]);
const comment = ref<string | null>(null);

const societyStore = useSocietyStore();
const userStore = useUsersStore();
const brandComposable = useBrandsComposable();

defineProps<{
  svg?: string;
}>();

const isValid = computed(() => isDefined(comment?.value) && comment.value);

const isLoading = ref(false);

async function contact() {
  isLoading.value = true;

  await $silex()
    .mail.create({
      textPlain: comment.value ?? undefined,
      textHtml: comment.value ?? undefined,
      idRole: Role.FACTURATION,
      idSociety: societyStore.society!.id!,
      to: brandComposable.getClientBrandConfig(
        societyStore.society?.idTypeBrand && societyStore.society.idTypeBrand
          ? societyStore.society.idTypeBrand
          : undefined,
      ).listMails?.facturation,
      fromAddress: userStore.user!.email!,
      subject: `Contact pour impayé [${societyStore.society?.diaCode ?? societyStore.society?.name}]`,
      fromName: `${userStore.user!.firstname} ${userStore.user!.lastname}`,
    })
    .then(() => {
      $notifier().open({ type: "success", content: "Message envoyé au service facturation" });
      emit("close");
    })
    .catch((error) => {
      $notifier().open({ content: apiErrorToString(error) });
    });

  isLoading.value = false;
}
</script>

<style lang="scss" scoped>
:deep(textarea) {
  resize: none;
  font-family: $default-font-family;
}
</style>
