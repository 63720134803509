<template>
  <VueFinalModal
    style="display: flex; justify-content: center; align-items: center"
    :click-to-close="false"
  >
    <div class="cgu-container">
      <div class="cgu-title uds-headers primary-color">
        Veuillez accepter les Conditions Générales d'Utilisation
      </div>
      <div
        class="cgu-content scrollbar"
        :class="{ '--shadow-bottom': isAtTop, '--shadow-top': isAtBottom }"
        @scroll="scrolling"
      >
        <div class="uds-headers --h3">ARTICLE 1 - PRÉAMBULE</div>
        <div class="uds-paragraph">
          Nous vous remercions d’avoir choisi les Services. Veuillez lire attentivement les
          présentes conditions générales (la “Convention”). Cette Convention est un accord juridique
          entre vous et la SAS COMPTALIB. Vous acceptez la présente Convention par voie électronique
          (par exemple,en cliquant sur «J’accepte») en installant, en accédant ou en utilisant les
          Services. Si vous n’acceptez pas la présente Convention, vous ne devez pas utiliser les
          Services. L’Abonnement aux Services entraîne l’acceptation expresse et sans réserve par
          l’Utilisateur de la présente Convention et exclut l’application de toutes dispositions
          différentes ou contraires pouvant figurer sur le site internet. La Convention s’applique
          également aux périodes d’utilisation gratuites éventuellement accordées par SAS COMPTALIB
          à ses Utilisateurs.
        </div>
        <div class="uds-headers --h3">ARTICLE 2 - DÉFINITION DES TERMES UTILISÉS</div>
        <div class="uds-paragraph">
          SAS {{ verb }} : Société proposant les Services, en partenariat avec la SAS COMPTALIB.
          Services : Ensemble des logiciels accessibles via www.gestion.legalstart.fr, en mode SAAS
          (software as a service) conçus et développés par la SAS COMPTALIB et proposé par la SAS
          {{ verb }} sous la marque « Clementine ». Utilisateur : Toute personne morale ou personne
          physique exploitant identifiée au cours du processus d’enregistrement dans le cadre du
          processus d’utilisation des Services. Abonnement : Contrat entre la SAS COMPTALIB et
          l’Utilisateur pour l’usage habituel des Services en échange d’un paiement forfaitaire.
          Identifiants : Informations personnelles de l’Utilisateur permettant de se connecter aux
          Services.
        </div>
        <div class="uds-headers --h3">ARTICLE 3 - PRÉSENTATION DES SERVICES</div>
        <div class="uds-paragraph">
          La SAS {{ verb }} met à la disposition de l’Utilisateur l’accès payant aux Services. Les
          Services permettent à l’Utilisateur de visualiser, par lecture simple, l’ensemble des
          comptes bancaires qu’il détient dans différentes banques ainsi que les opérations
          enregistrées sur ces comptes sous réserve que ceux-ci soient en mesure de récupérer les
          informations bancaires de l’Utilisateur. Les Services peuvent être modifiés en fonction
          des améliorations ou des mises à jour apportées sans information préalable de
          l’Utilisateur. Les Services sont destinés à une clientèle de professionnels pour les aider
          dans la gestion de leur activité. Il ne s’agit pas de logiciels de comptabilité et ne
          peuvent à eux seuls refléter l’exacte situation économique de l’activité de l’Utilisateur.
          A noter, les Services sont également disponibles pour une clientèle de particuliers.
        </div>
        <div class="uds-headers --h3">ARTICLE 4 - ACCÈS AUX SERVICES</div>
        <div class="uds-paragraph">
          Pour utiliser les logiciels de SAS COMPTALIB, nous conseillons que l’Utilisateur dispose
          d’un support type PC ou Mac, tablette ou smartphone lui permettant d’accéder à internet
          avec une connexion 512kbit/s au minimum et d’un navigateur Chrome en cas d’utilisation via
          le site internet. A défaut, nous ne pouvons pas garantir l’utilisation des Services.
          L’accès aux Services se fait depuis l’URL fournie par SAS {{ verb }} au moment de
          l’Abonnement. L’Utilisateur doit se créer un espace Utilisateur pour pouvoir accéder aux
          Services. La création d’un espace en ligne suppose que l’Utilisateur fournisse une adresse
          email de correspondance valide dont il est propriétaire ainsi qu’un mot de passe qui
          constitueront ses identifiants. Si l’adresse email n’est pas valide et/ou si l’Utilisateur
          n’en est pas propriétaire, la SAS COMPTALIB ne pourra pas garantir la confidentialité des
          données de l’Utilisateur. La SAS COMPTALIB s’efforcera d’offrir un accès 24 heures sur 24,
          tous les jours de l’année sauf en cas de force majeure, de pannes, de défaillances dues à
          l’hébergeur, d’interventions de maintenance ou d’amélioration ou de mises à jour
          nécessaires au bon fonctionnement des Services. L’interruption de service pour les mises à
          jour pouvant s’effectuer est susceptible d’entraîner une interruption de service de
          soixante minutes au maximum. La SAS COMPTALIB se réserve la possibilité d’interrompre,
          dans la limite du raisonnable, les Services pour procéder à une intervention technique de
          maintenance ou d’amélioration quel que soit le jour et la durée d’intervention. A cet
          égard, le client consent à recevoir toutes les mises à jour des logiciels. En cas de
          besoin, l’accès de l’Utilisateur peut être utilisé pour tester le service. La SAS
          COMPTALIB est tributaire des banques pour le taux de disponibilité des mises à jour des
          comptes bancaires qui est actuellement en moyenne de 99% par Utilisateur. Les services
          sont disponibles en langue française.
        </div>
        <div class="uds-headers --h3">ARTICLE 5 - CONNAISSANCE D’INTERNET</div>
        <div class="uds-paragraph">
          L’Utilisateur déclare avoir pris connaissance des caractéristiques et des limites
          d’internet décrites ci-après: - que les transmissions de données sur internet ne
          bénéficient que d’une fiabilité technique relative, celles-ci circulant sur des réseaux
          hétérogènes aux caractéristiques et capacités techniques diverses, que les données
          circulant sur internet ne sont pas protégées contre des détournements éventuels et que la
          communication de codes confidentiels et plus généralement de toute information à caractère
          sensible est effectué par l’Utilisateur à ses risques et périls, qu’il lui appartient de
          prendre toutes les mesures appropriées de façon à protéger ses propres données et
          logiciels stockés sur ses ordinateurs de la contamination par des virus ou des tentatives
          d’intrusion dans son système informatique par des tiers via le service d’accès. En
          conséquence de ce qui précède et en parfaite connaissance des caractéristiques d’internet,
          l’Utilisateur renonce à engager la responsabilité de la SAS COMPTALIB concernant un ou
          plusieurs des faits ou événements mentionnés ci-dessus.
        </div>
        <div class="uds-headers --h3">ARTICLE 6 - RÉCUPÉRATION DES DONNÉES BANCAIRES</div>
        <div class="uds-paragraph">
          Les Services permettent l’enregistrement d’un ou plusieurs comptes bancaires qui sont
          ensuite synchronisés quotidiennement. Afin de pouvoir enregistrer les comptes bancaires
          qu’il détient dans les Services, l’Utilisateur doit sélectionner le nom de l’établissement
          bancaire auprès duquel il détient le ou les comptes bancaires, puis enregistrer son
          identifiant ou numéro de compte ainsi que son ou ses mot(s) de passe auprès du ou des
          établissement(s) bancaire(s).Une fois que l’Abonné a renseigné ces informations et que son
          ou ses compte(s) a (ont) été synchronisé(s) avec les Services, la mise à jour de ses
          données devient automatique.
        </div>
        <div class="uds-headers --h3">ARTICLE 7 - ASSISTANCE INFORMATIQUE</div>
        <div class="uds-paragraph">
          La SAS COMPTALIB s’engage à mettre à la disposition de l’Utilisateur une assistance en
          cliquant sur l’onglet ” Support” présent sur les Services.
        </div>
        <div class="uds-headers --h3">ARTICLE 8 - DURÉE DE L'ABONNEMENT</div>
        <div class="uds-paragraph">
          En cas de prestation comptable associée, l’Abonnement est conclu pour la durée de la
          prestation comptable à compter de la signature de ladite prestation (sous réserve d’un
          délai raisonnable de mise en place). En l’absence de prestation comptable associée,
          l’Abonnement est conclu pour une durée d’une année à compter de la date d’inscription.
          L’Abonnement se reconduit par tacite reconduction chaque année.
        </div>
        <div class="uds-headers --h3">ARTICLE 9 - GESTION DES DONNÉES PERSONNELLES</div>
        <div class="uds-paragraph">
          Gestion, protection et confidentialité des données personnelles. En souscrivant son
          Abonnement, l’Utilisateur accepte que la SAS COMPTALIB puisse stocker, traiter et utiliser
          les données collectées de manière générale et donne expressément son accord pour que la
          SAS COMPTALIB ait accès aux données personnelles de l’Utilisateur relative à ses comptes
          bancaires (identifiant et mot de passe d’accès à la plateforme web de la banque, numéro du
          compte, solde du compte, opérations liées au compte par exemple), et à la levée du secret
          bancaire au profit de la SAS COMPTALIB. Ces informations sont à usage exclusif de la SAS
          COMPTALIB qui s’engage à les garder confidentielles et à n’en faire aucune utilisation
          autres que celle prévues pour l’exécution des Services et la réalisation des opérations de
          maintenance et d’assistance en ligne. A cet égard, dans le cadre de l’utilisation des
          Services, l’Utilisateur s’engage à ne pas usurper l’identité de quiconque par n’importe
          quel moyen, et notamment en utilisant leur adresse email, mots de passe et/ou identifiants
          bancaires. Par ailleurs, l’Utilisateur reconnait explicitement être informé que les
          documents archivés électroniquement grâce aux Services ne sauraient se substituer aux
          originaux en support papier ou électronique et qu’il doit par conséquent les conserver
          dans les délais prévus par la loi. Enfin, il est vivement recommandé à l’Utilisateur
          d’utiliser un support (ordinateur, tablette, smartphone ou autre) dont il est
          propriétaire. Aussi, l’Utilisateur devra immédiatement informer la SAS COMPTALIB en cas de
          perte, vol, détournement et plus généralement de toute compromission de la confidentialité
          des données bancaires qu’il a divulguées à la SAS COMPTALIB, aussi en cas de perte, vol,
          détournement du support sur lequel il a installé les Services. Conformément à l’article 34
          dela loi informatique et libertés, l’Utilisateur ayant transmis des données nominatives à
          la SAS COMPTALIB dispose d’un droit d’accès, de rectification, de modification et de
          suppression des données qui le concerne. L’Utilisateur peut exercer ce droit en écrivant
          par mail à hello@comptalib.com ou par courrier au siège social SAS COMPTALIB : 9, avenue
          du Rhin 54520 - LAXOU. Identifiants. Les identifiants sont strictement personnels et
          confidentiels. L’Utilisateur s’engage à les garder secrets et à ne pas les divulguer sous
          quelque forme que ce soit. Tout accès aux Services à l’aide de l’adresse email et du mot
          de passe sera réputé effectué par l’Utilisateur. En cas de perte ou de vol de l’un de ses
          identifiants, l’Utilisateur doit en avertir la SAS COMPTALIB sans délai en cliquant sur le
          lien “Mot de passe oublié?“. Un e-mail contenant un lien sécurisé permettant à
          l’Utilisateur de changer son mot de passe sera en conséquence envoyé et l’ancien mot de
          passe sera désactivé. L’Utilisateur accepte que la SAS COMPTALIB utilise ses données à des
          fins statistiques dès lors que celles-ci auront été rendues anonymes, de transférer ou
          céder à un tiers les résultats statistiques correspondants
        </div>
        <div class="uds-headers --h3">ARTICLE 10 - PROPRIÉTÉ DE LA SAS COMPTALIB</div>
        <div class="uds-paragraph">
          Les Services et chacun des éléments qui les composent sont la propriété intellectuelle
          exclusive de SAS COMPTALIB ou de ses partenaires. L’Abonnement ne confère à l’Utilisateur
          qu’un droit d’usage privé, personnel et non-transmissible sur lesdits Services.
          L’Utilisateur s’interdit tout agissement susceptible de porter atteinte directement ou non
          aux droits de propriétés détenus par SAS COMPTALIB ou de ses partenaires.
        </div>
        <div class="uds-headers --h3">ARTICLE 11 - RESPONSABILITÉ DE LA SAS COMPTALIB</div>
        <div class="uds-paragraph">
          L’Utilisateur reconnaît avoir pris connaissance de l’ensemble de ses obligations et plus
          généralement de toutes les conditions relatives à l’utilisation des Services proposés par
          la SAS COMPTALIB. L’obligation souscrite par la SAS COMPTALIB est une obligation de
          moyens. Dansce cadre, elle s’engage à apporter tout le soin et la diligence nécessaires à
          la fourniture d’un service de qualité. Dans l’hypothèse où la responsabilité de la SAS
          COMPTALIB serait engagée, les dommages et intérêts ne pourraient dépasser le montant de
          l’Abonnement annuel de l’Utilisateur. Cette dernière clause est considérée comme
          essentielle et déterminante par la SAS COMPTALIB qui n’aurait pas contracté sans elle. En
          outre, la SAS COMPTALIB ne saurait voir sa responsabilité engagée pour, notamment : les
          difficultés d’accès au site hébergé du fait de la saturation du réseau internet ; la
          contamination par virus des données et/ou logiciels de l’Utilisateur dont la protection
          incombe à ce dernier ; les intrusions malveillantes de tiers sur l’espace en ligne de
          l’Utilisateur, malgré les mesures raisonnables de sécurité mises en place par la SAS
          COMPTALIB. En aucun cas la responsabilité de la SAS COMPTALIB ne pourra être engagée pour
          les dommages indirects et notamment tout préjudice commercial, moral ou financier, y
          compris toute perte de bénéfices ou perte d’image de marque ayant pour cause, origine, ou
          fondement, l’utilisation des Services. La SAS COMPTALIB ne garantit pas que les Services
          sera exempt d’anomalies ou d’erreurs, ni que celles-ci pourront être corrigées, ni quel
          service fonctionnera sans interruption ou panne, ni également qu’il est compatible avec un
          matériel ou une configuration autre que celle préconisée par la SAS COMPTALIB.
        </div>
        <div class="uds-headers --h3">ARTICLE 12 - PRIX</div>
        <div class="uds-paragraph">
          Les Services sont facturés par le distributeur la SAS {{ verb }}.
        </div>
        <div class="uds-headers --h3">ARTICLE 13 - RÉSILIATION ET SUSPENSION DE L'ABONNEMENT</div>
        <div class="uds-paragraph">
          L’Utilisateur peut résilier en s’adressant à la SAS {{ verb }}. La SAS COMPTALIB peut
          également, à sa seule discrétion, et sans préavis résilier ou suspendre l’Abonnement si
          l’Utilisateur ne respecte pas la présente Convention.
        </div>
        <div class="uds-headers --h3">ARTICLE 14 - FORCE MAJEURE</div>
        <div class="uds-paragraph">
          La responsabilité de la SAS COMPTALIB ou de l’Utilisateur sera entièrement dégagée si
          l’inexécution des obligations prévues par la présente Convention résulte d’un cas de force
          majeure. La force majeure se définit comme un événement imprévisible, irrésistible et
          extérieur. Si l’exécution du contrat est empêchée ou limitée en raison d’un cas de force
          majeure, la SAS COMPTALIB ou l’Utilisateur sera alors dispensé de l’exécution des
          obligations contractuelles considérées. Si les cas de force majeure ont une durée
          d’existence supérieure à un (1) mois, le présent contrat sera résilié automatiquement,
          sauf accord contraire entre la SAS COMPTALIB et l’Utilisateur.
        </div>
        <div class="uds-headers --h3">ARTICLE 15 - MODIFICATION DE LA PRESENTE CONVENTION</div>
        <div class="uds-paragraph">
          Nous nous réservons le droit d’apporter des modifications à la présente Convention à tout
          moment, et celles-ci seront applicables une fois diffusées par l’intermédiaire des
          Services, sur notre site internet ou lorsque nous vous en informerons quels que soient les
          moyens utilisés. Nous pouvons également modifier les Services en tout ou en partie. Votre
          utilisation ininterrompue des Services constitue votre acceptation des modifications.
        </div>
        <div class="uds-headers --h3">ARTICLE 16 - DIFFÉRENDS</div>
        <div class="uds-paragraph">
          En cas de litige de toute nature, compétence expresse est attribuée au Tribunal de
          Commerce de Nancy, nonobstant pluralité de défendeurs ou appel en garantie. Cette
          compétence s’applique également en matière de référé. Les Parties déclarent leur intention
          de chercher au préalable une solution amiable à toute difficulté qui pourrait surgir à
          propos de l’application de la présente Convention.
        </div>
        <template v-if="legalMention">
          <div class="uds-headers --h3">ARTICLE 17 - MENTIONS LÉGALES</div>
          <div class="uds-paragraph">
            Les mentions légales sont disponibles sur :
            <NuxtLink :to="legalMention" target="_blank">
              {{ legalMention }}
            </NuxtLink>
          </div>
        </template>
      </div>
      <div class="cgu-footer">
        <uds-input-checkbox
          label="Je reconnais avoir pris connaissance des conditions générales d'utilisation et les accepte expressément."
          :is-checked="isAccepted"
          @input="isAccepted = !isAccepted"
        />
        <uds-main-button :loading="isLoading" :disabled="!isValid" @click="acceptCgu"
          >Valider</uds-main-button
        >
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { VueFinalModal } from "vue-final-modal";

const emit = defineEmits(["close"]);

const societyStore = useSocietyStore();
const { isPourcentage, isComptalib, isAnytime, isClementine, isComptaStart } =
  useBrandsComposable();
const idTypeBrand = computed(() => societyStore.society?.idTypeBrand ?? 1);

const isLoading = ref(false);
const isAtBottom = ref(false);
const isAtTop = ref(true);
const isAccepted = ref(false);
const hasScrollToBottom = ref(false);

const isValid = computed<boolean>(() => hasScrollToBottom.value && isAccepted.value);

const verb = computed<string>(() => {
  if (isPourcentage()) {
    switch (idTypeBrand.value) {
      case 1:
        return "EXPERTISE CHOIX C";
      case 5:
      case 7:
        return "Pourcentage ECE - AMEX";
      default:
        return "EXPERTISE CHOIX B";
    }
  } else if (isComptalib() || isAnytime()) {
    return "Comptalib";
  } else {
    return "EXPERTISE CHOIX B";
  }
});

const legalMention = computed<string | null>(() => {
  if (isClementine() || isComptaStart()) {
    return "https://www.compta-clementine.fr/mentions-legales/";
  } else if (isComptalib() || isAnytime()) {
    return "https://www.comptalib.com/mentions/";
  } else if (isPourcentage()) {
    return "https://www.pourcentage.fr/mentions-legales/";
  } else {
    return null;
  }
});

const scrolling = (e: any) => {
  const clientHeight = e.target.clientHeight;
  const scrollHeight = e.target.scrollHeight;
  const scrollTop = e.target.scrollTop;

  if (scrollTop + clientHeight >= scrollHeight - 2) {
    hasScrollToBottom.value = true;
    isAtBottom.value = true;
  } else {
    isAtBottom.value = false;
  }

  if (scrollTop <= 2) {
    isAtTop.value = true;
  } else {
    isAtTop.value = false;
  }
};

async function acceptCgu() {
  isLoading.value = true;
  await societyStore.updateCurrentSocietyConfig({
    acceptCgv: true,
  });
}

watch(
  () => societyStore.config?.acceptCgv,
  (value) => {
    if (value) {
      emit("close");
    }
  },
);
</script>

<style lang="scss" scoped>
.cgu-container {
  width: 90vw;
  height: 90vh;
  background: $uds-white;
  padding: $uds-spacing-4 $uds-spacing-6;
  display: flex;
  flex-direction: column;
  gap: $uds-spacing-3;

  .cgu-title {
    width: 100%;
    text-align: center;
  }

  .cgu-content {
    max-height: calc(90vh - $uds-spacing-3 * 2);
    padding: $uds-spacing-1 $uds-spacing-2;
    box-shadow:
      inset 0px 11px 8px -10px #ccc,
      inset 0px -11px 8px -10px #ccc;
    &.--shadow-top {
      box-shadow: inset 0px 11px 8px -10px #ccc;
    }
    &.--shadow-bottom {
      box-shadow: inset 0px -11px 8px -10px #ccc;
    }
  }

  .cgu-footer {
    display: flex;
    flex-direction: column;
    gap: $uds-spacing-1;
    align-items: center;
  }
}

.uds-paragraph {
  text-align: justify;
  margin-bottom: $uds-spacing-3;
}
</style>
