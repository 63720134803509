import { TimeSlot } from "@silexpert/core";

type OpenCalendlyAppointmentType = {
  idTypeCalendly?: TimeSlot;
  withoutType?: boolean;
};
export function useAccountantComposable() {
  const societyStore = useSocietyStore();

  function openCalendlyAppointment(params?: OpenCalendlyAppointmentType) {
    const idType = params?.idTypeCalendly ?? TimeSlot.ACCOUNTING_RDV;
    const society = societyStore.society;
    const link = params?.withoutType
      ? `/appointment?uuid=${society?.externalId}`
      : `/appointment?uuid=${society?.externalId}&idType=${idType}`;
    return navigateTo(link, {
      open: { target: "_blank" },
    });
  }

  return { openCalendlyAppointment };
}
