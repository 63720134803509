<template>
  <div class="column-container">
    <div>
      <div class="icon-title">
        <img
          v-if="isComptalib()"
          src="@/assets/img/drawer/sale.png"
          style="width: 18px; height: 18px"
          :style="`opacity: ${isComptalib() ? 1 : 0};`"
        >
        <uds-icon v-else icon-name="description" :color="primaryColor" />
        <div class="title-wrapper">Ventes</div>
      </div>
      <div class="list-of-links">
        <div class="a-link" @click="goToForm(estimateInvoiceType.FACTURE)">
          <span>Créer une facture</span>
        </div>
        <div class="a-link" @click="goToForm(estimateInvoiceType.AVOIR)">
          <span>Créer un avoir</span>
        </div>
        <div class="a-link" @click="openModalNewSaleInvoice">
          <span>Importer une facture</span>
        </div>
        <div class="a-link" @click="goToForm(estimateInvoiceType.DEVIS)">
          <span>Créer un devis</span>
        </div>
        <div class="a-link" @click="goToForm(estimateInvoiceType.BON)">
          <span>Créer un bon de commande</span>
        </div>
        <div class="a-link" @click="openModalNewArticle"><span>Créer un article</span></div>
        <div class="a-link" @click="openModalNewCustomer"><span>Créer un client</span></div>
        <template v-if="canCreatePaymentLink">
          <div class="a-link" @click="openNewPaymentModal"><span>Créer un paiement</span></div>
        </template>
        <template v-if="isAccruals">
          <div class="a-link" @click="openZTicketModal"><span>Importer un ticket Z</span></div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { EstimateInvoiceType } from "@silexpert/core";
import { useModal } from "vue-final-modal";
import ImportInvoiceModal from "~/components/commons/modals/ImportInvoiceModal.vue";
import ImportZTicket from "~/components/commons/modals/ImportZTicket.vue";
import NewPaymentModal from "~/components/commons/modals/NewPaymentModal.vue";
import NewProduct from "~/components/commons/modals/NewProduct.vue";
import NewThirdPartyModal from "~/components/commons/modals/NewThirdPartyModal.vue";
import TypeImportModal from "~/components/commons/modals/TypeImportModal.vue";

const { isComptalib, hasOneOfTheseBrands } = useBrandsComposable();

const emit = defineEmits(["close"]);

const societyStore = useSocietyStore();
const connectorStore = useConnectorStore();

const primaryColor = computed(() => {
  return getPrimaryColors().primary500;
});
const isAccruals = computed(() => societyStore.isAccruals);

const canCreatePaymentLink = computed<boolean>(() => {
  const isAllowedBrand = hasOneOfTheseBrands([
    EBrandNames.COMPTALIB,
    EBrandNames.CLEMENTINE,
    EBrandNames.COMPTASTART,
  ]);
  return isAllowedBrand && isDefined(connectorStore.stripe);
});

const estimateInvoiceType = computed(() => EstimateInvoiceType);

async function openModalNewArticle() {
  emit("close");
  const productStore = useProductStore();

  const { open, close } = useModal({
    component: NewProduct,
    attrs: {
      help: "Ajouter un article vous permettra d'y accéder plus facilement et directement lors de la création d'une facture, devis, avoir, etc.",
      svg: isComptalib() ? "ComptalibArticleSvg" : "ArticleSvg",
      async onClose(): Promise<void> {
        const newLink = useBuildRouteFromQueryPropertiesComposable(
          defaultProductsQueryProperties.value,
          {
            ...productStore.queryProperties,
            page: 1,
            productId: null,
          },
          "/sales/products",
        );
        await navigateTo(`${newLink}#forceRefresh`);
        close();
      },
    },
  });
  await open();
}

async function openModalNewCustomer() {
  emit("close");
  const thirdPartyStore = useThirdPartiesStore();
  const { open, close } = useModal({
    component: NewThirdPartyModal,
    attrs: {
      isCustomer: true,
      async onSelect() {
        const newLink = useBuildRouteFromQueryPropertiesComposable(
          defaultThirdPartiesQueryProperties.value,
          {
            ...thirdPartyStore.queryProperties,
            tab: "customers",
          },
          "/sales/customers",
        );
        await navigateTo(`${newLink}#forceRefresh`);
        close();
      },
      onCancel() {
        close();
      },
    },
  });
  await open();
}

async function openModalNewSaleInvoice() {
  emit("close");

  if (societyStore.isAccruals) {
    const { open, close } = useModal({
      component: TypeImportModal,
      attrs: {
        onClose() {
          close();
        },
      },
    });
    await open();
  } else {
    const saleStore = useSalesStore();
    const { open, close } = useModal({
      component: ImportInvoiceModal,
      attrs: {
        isSales: true,
        async onClose() {
          close();
          const link = useBuildRouteFromQueryPropertiesComposable(
            defaultSalesQueryProperties.value,
            {
              ...saleStore.queryProperties,
              page: 1,
              limit: 25,
            },
            "/sales",
          );
          await navigateTo(`${link}#forceRefresh`);
        },
        onCancel() {
          close();
        },
      },
    });
    await open();
  }
}

async function openZTicketModal() {
  emit("close");
  const zTicketStore = useZTicketsStore();
  const { open, close } = useModal({
    component: ImportZTicket,
    attrs: {
      async onSave() {
        close();
        const newLink = useBuildRouteFromQueryPropertiesComposable(
          defaultZTicketsQueryProperties.value,
          {
            ...zTicketStore.queryProperties,
            page: 1,
            zTicketId: null,
          },
          "/sales/ztickets",
        );
        await navigateTo(`${newLink}#forceRefresh`);
      },
      onClose() {
        close();
      },
      onCancel() {
        close();
      },
    },
  });
  await open();
}

async function openNewPaymentModal() {
  const { open, close } = useModal({
    component: NewPaymentModal,
    attrs: {
      onClose() {
        close();
      },
      onCancel() {
        close();
      },
    },
  });
  await open();
}

function goToForm(type: EstimateInvoiceType) {
  return navigateTo(`/sales/form/${type}`);
}
</script>

<style lang="scss" scoped>
.column-container {
  width: 217px;

  .icon-title {
    display: flex;
    align-items: flex-start;
    margin-left: 18px;

    .icon {
      font-size: 20px;
      color: $uds-primary-500;
    }

    .title-wrapper {
      margin-left: 5px;
      margin-bottom: $uds-spacing-1;

      font-family: $default-font-family;
      font-style: normal;
      font-weight: $uds-weight-700;
      font-size: 16px;
      line-height: 20px;
      color: $uds-neutral-900;
    }
  }
  .list-of-links {
    margin-left: $uds-spacing-3;
    margin-top: $uds-spacing-0_5;
    .a-link {
      margin-bottom: 6px;

      font-family: $default-font-family;
      font-style: normal;
      font-weight: $uds-weight-450;
      font-size: 14px;
      line-height: 16px;
      color: $uds-neutral-800;
      cursor: pointer;

      &:before {
        content: ">";
        margin-right: $uds-spacing-0_5;
      }

      &:hover {
        color: $uds-neutral-900;
      }
    }
  }
}
</style>
