<template>
  <uds-simple-dropdown ref="dropdown" placement="bottom-end">
    <template #inputContent>
      <div>
        <uds-action-button icon-name="add" :color="color" class="icon" />
      </div>
    </template>
    <template #dropdownContent>
      <NavbarPlusMenu @close="closeDropdown" />
    </template>
  </uds-simple-dropdown>
</template>

<script setup lang="ts">
const dropdown = ref(null);
// @ts-expect-error
const open = computed(() => dropdown.value?.isMenuShown);

function closeDropdown() {
  // @ts-expect-error
  dropdown.value.isMenuShown = false;
}

const color = computed(() => (open.value ? "primary" : ""));
</script>
