<template>
  <div class="notification" :class="{ active: !notification.read }">
    <header>
      <div class="title">
        <div class="dot" :class="{ active: !notification.read }" />
        <div class="text">{{ notification.title }}</div>
      </div>
      <div class="read-icon">
        <uds-tooltip v-if="!notification.read" tooltip-text="Marquer comme lu">
          <uds-icon
            icon-name="drafts"
            size="small"
            :color="udsColors.udsNeutral800"
            @click="notificationStore.markAsRead(true, [notification.id])"
          />
        </uds-tooltip>
        <uds-tooltip v-else tooltip-text="Marquer comme non lu">
          <uds-icon
            icon-name="mark_as_unread"
            size="small"
            :color="udsColors.udsNeutral800"
            @click="notificationStore.markAsRead(false, [notification.id])"
          />
        </uds-tooltip>
      </div>
    </header>

    <div class="uds-paragraph --small">{{ notification.message }}</div>

    <footer>
      <div class="uds-paragraph --small date">
        {{ dayjs(notification.createdAt).format("D MMM YYYY") }}
      </div>
      <div class="uds-paragraph --small explicit-link" @click="goTo()">
        {{ getLinkName(notification.idNotificationtype) }}
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts">
import type { IAppNotification, NotificationTypeType } from "@silexpert/core";
import { NotificationType } from "@silexpert/core";

const dayjs = useDayjs();
const notificationStore = useNotificationStore();

const props = defineProps<{
  notification: IAppNotification;
}>();

const udsColors = getUdsColors();

const emit = defineEmits(["close"]);

function getLinkName(idNotificationType: number): string {
  const notificationType: NotificationTypeType[] = Object.values(NotificationType);
  return notificationType?.find((nt) => nt.id === idNotificationType)?.linkTitle ?? "";
}

async function goTo() {
  try {
    notificationStore.markAsRead(true, [props.notification.id]);
    if (props.notification.link) await navigateTo(props.notification.link);
    emit("close");
  } catch (error) {
    $notifier().open({ type: "error", content: apiErrorToString(error) });
  }
}
</script>

<style scoped lang="scss">
.notification {
  border-bottom: 1px solid $uds-neutral-400;
  padding: $uds-spacing-2 $uds-spacing-3;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  gap: $uds-spacing-1;

  &.active {
    background-color: $uds-primary-50;

    .explicit-link {
      color: $uds-primary-500 !important;
    }
  }

  &:hover:not(.active) {
    background-color: $uds-neutral-50;
  }

  &:hover {
    .read-icon {
      visibility: visible;
      display: inline-flex;
      gap: $uds-spacing-1;
    }
  }

  header {
    display: flex;
    justify-content: space-between;

    .title {
      display: flex;
      align-items: center;
      .dot {
        margin-right: $uds-spacing-1;
        background-color: $uds-neutral-600;
        width: 8px;
        height: 8px;
        border-radius: 100%;

        &.active {
          background-color: $uds-primary-500;
        }
      }
    }

    &.active {
      background-color: $uds-primary-50;
    }

    .read-icon {
      visibility: hidden;
      display: inline-flex;
      gap: $uds-spacing-1;
    }
  }

  footer {
    display: flex;
    justify-content: space-between;

    .date {
      color: $uds-neutral-800;
    }
    .explicit-link {
      color: $uds-neutral-800;
    }
  }
}
</style>
