<template>
  <div id="subscription-bar" class="scrollbar">
    <div>
      <p>
        Des milliers d’entreprises utilisent déjà {{ brandName }} pour la gestion de leur activité.
        👉
        <span
          v-if="isComptalib()"
          style="text-decoration: underline; font-weight: 300"
          @click="goToSubscription()"
          >Souscrire ici</span
        >
        <span
          v-else
          :style="
            !hasAlreadyAskForContact
              ? 'text-decoration: underline; font-weight: 300;'
              : ' font-weight: 300;'
          "
          @click="createContact()"
          >{{ hasAlreadyAskForContact ? "Devis demandé" : "Demander un devis" }}</span
        >
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
const userStore = useUsersStore();

const { getBrandName, isComptalib } = useBrandsComposable();
const brandName = getBrandName();

const threeMonthAgo = new Date();
threeMonthAgo.setMonth(threeMonthAgo.getMonth() - 3);
const hasAlreadyAskForContact = computed(() => {
  if (!userStore.user) return false;

  // Check if the user has already ask for contact in the last 3 months
  const askedForContact = userStore.contactRequests.filter(
    (contactRequest) =>
      isDefined(contactRequest.createdAt) && new Date(contactRequest.createdAt) > threeMonthAgo,
  );

  return askedForContact.length > 0;
});

async function createContact() {
  if (isDefined(userStore.user)) {
    try {
      await $silex().customerActivity.createContactRequests(userStore.user.id ?? -1, {
        idSource: 100,
      });
      userStore.fetchContactHistory();
    } catch (error) {
      $notifier().open({ type: "error", content: apiErrorToString(error) });
    }
  }
}

function goToSubscription() {
  return navigateTo("/settings/subscription");
}
</script>

<style lang="scss" scoped>
#subscription-bar {
  display: flex;
  position: fixed;
  height: 50px;
  z-index: 10;
  width: 100%;
  background-color: $uds-primary-500;
  justify-content: center;
  align-content: center;
  p {
    color: $uds-white;
    font-size: 14px;
    span {
      cursor: pointer;
    }
  }
  & > div {
    height: 50px;
  }
}
</style>
