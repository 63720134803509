<template>
  <div/>
</template>
<script setup lang="ts">
import { Role } from "@silexpert/core";
import { useModal } from "vue-final-modal";
import Cgu from "~/components/commons/modals/CguModal.vue";

const societyStore = useSocietyStore();
const roleComposable = useRolesComposable();

const acceptCgv = computed(() => societyStore.config?.acceptCgv);
const isClient = computed(() => roleComposable.hasOneOfTheseRoles([Role.CLIENT]));

onMounted(() => {
  if (!acceptCgv.value && isClient.value) show();
});

async function show() {
  const { open, close } = useModal({
    component: Cgu,
    attrs: {
      onClose() {
        close();
      },
    },
  });
  await open();
}
</script>
