<template>
  <div class="column-container">
    <div>
      <div class="icon-title">
        <img
          v-if="isComptalib()"
          src="@/assets/img/drawer/bank.png"
          style="width: 18px; height: 18px"
          :style="`opacity: ${isComptalib() ? 1 : 0};`"
        >
        <uds-icon v-else icon-name="assessment" :color="primaryColor" />
        <div class="title-wrapper">Banques</div>
      </div>
      <div class="list-of-links">
        <div class="a-link" @click="openModalImportTransaction">
          <span>Ajouter des opérations</span>
        </div>
        <div class="a-link" @click="openCashModal">
          <span>Déclarer une opération en espèces</span>
        </div>
      </div>
    </div>
    <div style="margin-top: 16px">
      <div class="icon-title">
        <img
          v-if="isComptalib()"
          src="@/assets/img/drawer/purchase.png"
          style="width: 18px; height: 18px"
          :style="`opacity: ${isComptalib() ? 1 : 0};`"
        >
        <uds-icon v-else icon-name="shopping_cart" :color="primaryColor" />
        <div class="title-wrapper">Achats</div>
      </div>
      <div class="list-of-links">
        <div class="a-link" @click="openModalNewInvoice">
          <span>Importer une facture</span>
        </div>
        <div class="a-link" @click="openModalNewExpenseNote">
          <span>Déclarer une note de frais</span>
        </div>
        <div class="a-link" @click="openModalNewKilometricAllowance">
          <span>Déclarer une indemnité kilométrique</span>
        </div>
        <div class="a-link" @click="openModalNewProvider"><span>Créer un fournisseur</span></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useModal } from "vue-final-modal";
import ImportInvoiceModal from "~/components/commons/modals/ImportInvoiceModal.vue";
import NewExpenseNoteAccrualModal from "~/components/commons/modals/NewExpenseNoteAccrualModal.vue";
import NewExpenseNoteCashBasedModal from "~/components/commons/modals/NewExpenseNoteCashBasedModal.vue";
import NewKilometricAllowanceModal from "~/components/commons/modals/NewKilometricAllowanceModal.vue";
import NewThirdPartyModal from "~/components/commons/modals/NewThirdPartyModal.vue";
import NewTransactionsModal from "~/components/commons/modals/NewTransactionsModal.vue";
import NewCashDeclarationModal from "~/components/commons/modals/banks/NewCashDeclaration/NewCashDeclarationModal.vue";

const emit = defineEmits(["close"]);

const { isComptalib } = useBrandsComposable();
const primaryColor = computed(() => {
  return getPrimaryColors().primary500;
});

async function openCashModal() {
  emit("close");
  const { open, close } = useModal({
    component: NewCashDeclarationModal,
    attrs: {
      onCancel() {
        close();
      },
      onClose() {
        close();
      },
    },
  });
  await open();
}

async function openModalImportTransaction() {
  emit("close");
  const { open, close } = useModal({
    component: NewTransactionsModal,
    attrs: {
      onClose() {
        close();
      },
      onCancel() {
        close();
      },
    },
  });
  await open();
}

async function openModalNewInvoice() {
  emit("close");
  const purchasesStore = usePurchasesStore();

  const { open, close } = useModal({
    component: ImportInvoiceModal,
    attrs: {
      isSales: false,
      async onClose() {
        close();
        const link = useBuildRouteFromQueryPropertiesComposable(
          defaultPurchasesQueryProperties.value,
          {
            ...purchasesStore.queryProperties,
            page: 1,
            limit: 25,
          },
          "/purchases",
        );
        await navigateTo(`${link}#forceRefresh`);
      },
      onCancel() {
        close();
      },
    },
  });
  await open();
}
async function openModalNewExpenseNote() {
  emit("close");
  const bankStore = useBankStore();
  const societyStore = useSocietyStore();
  if (societyStore.isCashBased) {
    const { open, close } = useModal({
      component: NewExpenseNoteCashBasedModal,
      attrs: {
        help: `Importez vos factures de ventes (maximum 10 documents de 2MB chacun).`,
        svg: isComptalib() ? "ComptalibExpenseNoteSvg" : "ExpenseNoteSvg",
        onCancel() {
          close();
        },
        async onClose() {
          const link = useBuildRouteFromQueryPropertiesComposable(
            defaultBankQueryProperties.value,
            {
              ...bankStore.queryProperties,
            },
            "/purchases/expense-notes",
          );
          await navigateTo(`${link}#forceRefresh`);
          close();
        },
      },
    });
    await open();
  } else {
    const { open, close } = useModal({
      component: NewExpenseNoteAccrualModal,
      attrs: {
        svg: isComptalib() ? "ComptalibExpenseNoteSvg" : "ExpenseNoteSvg",
        onCancel() {
          close();
        },
        async onClose() {
          const link = useBuildRouteFromQueryPropertiesComposable(
            defaultBankQueryProperties.value,
            {
              ...bankStore.queryProperties,
            },
            "/purchases",
          );
          await navigateTo(`${link}#forceRefresh`);
          close();
        },
      },
    });
    await open();
  }
}
async function openModalNewKilometricAllowance() {
  emit("close");
  const { open, close } = useModal({
    component: NewKilometricAllowanceModal,
    attrs: {
      onCancel() {
        close();
      },
      onClose() {
        close();
      },
    },
  });

  await open();
}

async function openModalNewProvider() {
  emit("close");
  const thirdPartyStore = useThirdPartiesStore();
  const { open, close } = useModal({
    component: NewThirdPartyModal,
    attrs: {
      isCustomer: false,
      async onSelect() {
        const newLink = useBuildRouteFromQueryPropertiesComposable(
          defaultThirdPartiesQueryProperties.value,
          {
            ...thirdPartyStore.queryProperties,
            tab: "providers",
          },
          "/purchases/providers",
        );
        await navigateTo(`${newLink}#forceRefresh`);
        close();
      },
      onCancel() {
        close();
      },
    },
  });
  await open();
}
</script>

<style lang="scss" scoped>
.column-container {
  .icon-title {
    display: flex;
    align-items: flex-start;
    margin-left: 18px;

    .icon {
      font-size: 20px;

      color: $uds-primary-500;
    }

    .title-wrapper {
      margin-left: 5px;
      margin-bottom: $uds-spacing-1;

      font-family: $default-font-family;
      font-style: normal;
      font-weight: $uds-weight-700;
      font-size: 16px;
      line-height: 20px;
      color: $uds-neutral-900;
    }
  }
  .list-of-links {
    margin-left: $uds-spacing-3;
    margin-top: $uds-spacing-0_5;

    .a-link {
      margin-bottom: 6px;

      font-family: $default-font-family;
      font-style: normal;
      font-weight: $uds-weight-450;
      font-size: 14px;
      line-height: 16px;
      color: $uds-neutral-800;
      cursor: pointer;

      &:before {
        content: ">";
        margin-right: $uds-spacing-0_5;
      }

      &:hover {
        color: $uds-neutral-900;
      }
    }
  }
}
</style>
