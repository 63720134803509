<template>
  <div class="column-container">
    <div>
      <div class="icon-title">
        <img
          v-if="isComptalib()"
          src="@/assets/img/drawer/library.png"
          style="width: 18px; height: 18px"
          :style="`opacity: ${isComptalib() ? 1 : 0};`"
        >
        <uds-icon v-else icon-name="work" :color="primaryColor" />
        <div class="title-wrapper">Bibliothèque</div>
      </div>
      <div class="list-of-links">
        <div class="a-link" @click="openLibraryModal"><span> Importer un document</span></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useModal } from "vue-final-modal";
import NewDocument from "~/components/commons/modals/NewDocument.vue";

const { isComptalib } = useBrandsComposable();

const emit = defineEmits(["close"]);

const primaryColor = computed(() => {
  return getPrimaryColors().primary500;
});

async function openLibraryModal() {
  emit("close");
  const { open, close } = useModal({
    component: NewDocument,
    attrs: {
      onClose() {
        close();
      },
      onCancel() {
        close();
      },
    },
  });
  await open();
}
</script>

<style lang="scss" scoped>
.column-container {
  .icon-title {
    display: flex;
    align-items: flex-start;
    margin-left: 18px;

    .icon {
      font-size: 20px;
      color: $uds-primary-500;
    }

    .title-wrapper {
      margin-left: 5px;
      margin-bottom: $uds-spacing-1;

      font-family: $default-font-family;
      font-style: normal;
      font-weight: $uds-weight-700;
      font-size: 16px;
      line-height: 20px;
      color: $uds-neutral-900;
    }
  }
  .list-of-links {
    margin-left: $uds-spacing-3;
    margin-top: $uds-spacing-0_5;
    .a-link {
      margin-bottom: 6px;

      font-family: $default-font-family;
      font-style: normal;
      font-weight: $uds-weight-450;
      font-size: 14px;
      line-height: 16px;
      color: $uds-neutral-800;
      cursor: pointer;

      &:before {
        content: ">";
        margin-right: $uds-spacing-0_5;
      }

      &:hover {
        color: $uds-neutral-900;
      }
    }
  }
}
</style>
