<template>
  <div
    v-if="hasSocietyNewFeatures"
    class="new-features-container uds-shadow-l"
    :class="{ '--single': features.length === 1 }"
  >
    <div class="sub-container">
      <div class="items">
        <LayoutNewFeatureItem :feature="features[currentIndex]" @close="triggerFetchInfo" />
      </div>
      <div v-if="features.length > 1" class="multi-feature-management">
        <ClientOnly>
          <div class="stepper">
            <div class="step-item-container">
              <ul class="steps-list">
                <li
                  v-for="(f, index) in features"
                  :key="`feature-management-${index}`"
                  class="step-item"
                  :class="{
                    '--pointer': index !== currentIndex,
                  }"
                  @click="currentIndex = index"
                >
                  <div class="step-marker">
                    <div class="step-marker-background"/>
                    <div
                      v-show="index === currentIndex"
                      class="step-marker-progress"
                      :style="`width: 100%`"
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </ClientOnly>
        <div class="arrows">
          <uds-icon
            icon-name="chevron_left"
            @click="
              currentIndex === 0
                ? (currentIndex = features.length - 1)
                : (currentIndex = currentIndex - 1)
            "
          />
          <uds-icon
            icon-name="chevron_right"
            @click="
              currentIndex === features.length - 1
                ? (currentIndex = 0)
                : (currentIndex = currentIndex + 1)
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { NewFeature, NewFeatures } from "~/content/features/type";

const societyStore = useSocietyStore();
const { getBrandName } = useBrandsComposable();
const dayjs = useDayjs();

const newFeaturesContent = ref<NewFeatures | null>(null);

const currentIndex = ref(0);

// Display news if is date is after the society creation + 2 month
const societyDateMin2Month = computed(() =>
  dayjs(societyStore.society?.dateInscription ?? societyStore.society?.createdAt).subtract(
    2,
    "month",
  ),
);

const features = computed<NewFeature[]>(() => {
  const f: NewFeature[] = newFeaturesContent.value?.features ?? [];
  return f.filter(
    (feat) =>
      !societyStore.visualizedInformations.map((vi) => vi.key).includes(feat.key) &&
      feat.brands.includes(getBrandName()) &&
      feat.comptaTypes.includes(societyStore.isAccruals ? "accruals" : "cashBased") &&
      dayjs().isAfter(dayjs(feat.displayDate)) &&
      dayjs(societyDateMin2Month.value).isBefore(feat.displayDate),
  );
});

const hasSocietyNewFeatures = computed<boolean>(() => !!features.value.length);

onMounted(async () => {
  newFeaturesContent.value = (await queryContent(
    "/features/new-features",
  ).findOne()) as unknown as NewFeatures;
});

async function triggerFetchInfo() {
  await societyStore.fetchVisualizedInformations(societyStore.society!.id!);
  currentIndex.value = 0;
}
</script>

<style lang="scss" scoped>
.new-features-container.--single {
  padding-bottom: $uds-spacing-4;
}
.new-features-container {
  display: flex;
  gap: $uds-spacing-3;
  align-items: center;
  padding-bottom: $uds-spacing-1_5;
  position: fixed;
  z-index: 99999; // lvl 5
  bottom: $uds-spacing-3;
  right: $uds-spacing-3;

  font-weight: $uds-weight-450;
  font-size: 16px;
  background: $uds-white;
  border-radius: $uds-radius-2;
  overflow: hidden;
  width: 628px;

  .multi-feature-management {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: $uds-spacing-1_5;
    padding-left: $uds-spacing-4;
    padding-right: $uds-spacing-4;
    justify-content: space-between;
    .arrows {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: $uds-spacing-1;
    }
    .arrows :deep(.mdi-container) {
      cursor: pointer;
    }

    .stepper {
      flex: 1;
      padding-right: $uds-spacing-2;
      .step-item-container {
        flex: 1;
        .steps-list {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          list-style-type: none;
          padding: 0;
          z-index: 2;
          column-gap: $uds-spacing-1;

          .step-item {
            cursor: default;
            flex: 1;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            .step-name {
              margin-bottom: $uds-spacing-0_5;
            }

            .step-marker {
              position: relative;
              height: 4px;
              background-color: $uds-neutral-500;
              border-radius: $uds-radius-2;
              overflow: hidden;

              .step-marker-background {
                height: 100%;
                width: 100%;
                background-color: $uds-neutral-500;
                border-radius: $uds-radius-2;
                z-index: 1;
              }
              .step-marker-progress {
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: 0%;
                background-color: $uds-primary-500;
                border-radius: $uds-radius-2;
                z-index: 2;
                -webkit-transition: width 500ms ease-in-out;
                -moz-transition: width 500ms ease-in-out;
                -o-transition: width 500ms ease-in-out;
                transition: width 500ms ease-in-out;
              }
            }

            &.color-step {
              .step-marker {
                background: $uds-primary-500;
                color: $uds-white;
              }
            }

            &.--pointer {
              cursor: pointer;
              &:hover {
                .step-marker {
                  background: $uds-primary-300;
                }
              }
            }
          }
        }
      }
    }
  }

  .sub-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
</style>
