<template>
  <div class="navbar_container" :class="{ 'uds-shadow-s': !isOnTop }">
    <div class="navbar_container--left">
      <div class="navbar_icon_burger" @click="$emit('toggle-drawer')">
        <span class="material-symbols-rounded">notes</span>
      </div>
      <img :src="logoUrl" alt="logo" :height="isComptalib() ? '28px' : '35px'" >
    </div>

    <div class="navbar_container--right">
      <template v-if="society?.id">
        <div v-if="isAllowedToAccessAdmin">
          <span class="society_name">{{ identifier }} | {{ societyStore.accountancy }}</span>
        </div>
        <template v-if="isAllowedToAccessAdmin">
          <div v-if="!isExpertAccountant" class="home">
            <uds-tooltip tooltip-text="Retour à l'administration">
              <uds-action-button
                color="error"
                icon-name="home"
                size="small"
                @click="authentificationStore.logOutToAdmin(`/admin/societies`)"
              />
            </uds-tooltip>
          </div>

          <div>
            <uds-tooltip :tooltip-text="tooltitpText">
              <uds-action-button
                color="error"
                icon-name="undo"
                size="small"
                @click="handleRedirect"
              />
            </uds-tooltip>
          </div>

          <uds-tooltip tooltip-text="Rafraîchir toutes les données">
            <uds-action-button
              color="error"
              icon-name="refresh"
              size="small"
              @click="handleRefresh"
            />
          </uds-tooltip>
        </template>

        <NavbarFirstStepButton v-if="showFirstStepsButton" />
      </template>

      <NavbarSettingButton v-if="isComptalib() && society?.id" />

      <NavbarHelpButton v-if="isComptalib() || isClementine()" />

      <NavbarNotificationButton />

      <NavbarSponsorButton v-if="isClementine()" />

      <NavbarPlusButton />

      <NavbarUserMenu />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ISociety } from "@silexpert/core";
import { RoleAllowedToLogInAsClient } from "@silexpert/core";
import { useModal } from "vue-final-modal";
import CommonsModalsWaiting from "@/components/commons/modals/Waiting.vue";

defineEmits<{
  (e: "toggle-drawer"): void;
}>();
const { isClementine, getBrand, getClientBrandConfig, isComptalib } = useBrandsComposable();

const societyStore = useSocietyStore();
const authentificationStore = useAuthentificationStore();
const roleComposable = useRolesComposable();
const navigationStore = useNavigationStore();
const brandComposable = useBrandsComposable();

const isOnTop = computed(() => navigationStore.isOnTop);

const society = computed<ISociety | null>(() => societyStore.society);
const isAllowedToAccessAdmin = computed<boolean>(() =>
  roleComposable.hasOneOfTheseRoles(RoleAllowedToLogInAsClient),
);

const logoUrl = computed(() => `${getClientBrandConfig().urlS3}config/${getBrand().logo}`);

const identifier = computed(() =>
  truncateText(`#${society.value?.id} ${society.value?.name ?? ""}`, { length: 36 }),
);

/**
 * First steps button
 */
const showFirstStepsButton = computed<boolean>(() => {
  return (
    !isDefined(societyStore.society?.completedFirstStepAt) &&
    !isDefined(societyStore.society?.canceledFirstStepTourAt) &&
    !brandComposable.isAnytime()
  );
});

const isExpertAccountant = computed<boolean>(() => roleComposable.isPartnerAccountantUser());
const tooltitpText = ref<string>(
  isExpertAccountant.value ? "Retour à mon espace" : "Retour à l'espace admin de la société",
);

async function handleRedirect() {
  const society = societyStore.society;
  if (isExpertAccountant.value) {
    await authentificationStore.resetAll({ shouldKeepUser: true });
    return navigateTo(`/expert/performances`);
  } else {
    return navigateTo(`/admin/societies/${society?.id}/config/information`);
  }
}
const { open, close } = useModal({
  component: CommonsModalsWaiting,
});

const exerciceStore = useExerciceStore();
const prestationStore = usePrestationStore();
const subscriptionStore = useSubscriptionStore();
const idSociety = computed(() => societyStore?.society?.id ?? 0);

async function handleRefresh() {
  await open();
  await authentificationStore.resetAll({ shouldKeepUserAndSociety: true }); // Chargement de toutes les informations nécessaires à un affichage direct
  await Promise.all([
    exerciceStore.fetchExercices(idSociety.value),
    prestationStore.fetchSocietyPrestations(idSociety.value),
  ]);

  // Chargement des informations nécessaires, nécessitant des informations déjà chargées
  await Promise.all([
    subscriptionStore.canSubscribe
      ? subscriptionStore.fetch()
      : new Promise((resolve) => resolve(true)),
  ]);

  await authentificationStore.fetchAllData(idSociety.value);

  close();
  await reloadNuxtApp();
}
</script>
<style lang="scss" scoped>
.navbar_container {
  display: flex;
  position: sticky;
  align-items: center;
  justify-content: space-between;
  height: $uds-navbar-height;
  top: 0;
  width: -webkit-fill-available;
  width: -moz-available;
  padding: 0 $uds-spacing-3;
  z-index: 1000;
  background: $uds-background-color;

  .navbar_container--left {
    display: flex;
    align-items: center;
    gap: $uds-spacing-2;

    > div {
      cursor: pointer;
    }
  }

  .navbar_container--right {
    display: flex;
    align-items: center;
    gap: $uds-spacing-2;

    > div {
      cursor: pointer;
    }

    .society_name {
      color: $uds-primary-500;
      cursor: default;
    }
  }
}
</style>
