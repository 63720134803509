<template>
  <CommonsModalsModalBody height="500px">
    <template #title/>
    <template #content>
      <div style="text-align: center">
        <img src="~/assets/img/error-oups.png" >
        <div class="uds-headers">Votre abonnement risque d'expirer !</div>
        <div v-if="subscriptionStore.status === 'past_due'" class="uds-paragraph">
          Votre méthode de paiement a expiré, veuillez la mettre à jour.
        </div>
        <div v-else-if="subscriptionStore.status === 'unpaid'" class="uds-paragraph">
          Vous êtes en impayé, merci de régulariser votre abonnement.
        </div>
      </div>
    </template>
    <template #footer>
      <uds-main-button :loading="false" :disabled="false" size="small" @click="goToSubscription()">
        <template v-if="subscriptionStore.status === 'past_due'">Mettre à jour</template>
        <template v-else-if="subscriptionStore.status === 'unpaid'">Régulariser</template>
      </uds-main-button>
    </template>
  </CommonsModalsModalBody>
</template>

<script setup lang="ts">
const subscriptionStore = useSubscriptionStore();

const emit = defineEmits(["close", "cancel"]);

async function goToSubscription() {
  await navigateTo("/settings/subscription");
  emit("close");
}
</script>

<style lang="scss" scoped>
:deep(.modal_container .modal_side_info) {
  display: none;
}

.pay-button {
  margin-left: 20px;
}

:deep(.uds-amounts) {
  margin-left: 10px;
}
</style>
