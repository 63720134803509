<template>
  <uds-simple-dropdown ref="dropdown" placement="bottom">
    <template #inputContent>
      <div class="notifications-button" :class="{ active: open }">
        <uds-icon
          icon-name="notifications"
          size="medium"
          :color="
            open || notificationsToRead.length > 0
              ? primaryColors.primary500
              : udsColors.udsNeutral800
          "
        />

        <!-- NB NOTIFICATIONS UNREAD CHIP -->
        <div
          v-if="notificationsToRead.length > 0"
          class="round-count"
          :style="{ backgroundColor: udsColors.udsError500 }"
        >
          {{ notificationsToRead.length > 99 ? "99+" : notificationsToRead.length }}
        </div>
      </div>
    </template>
    <template #dropdownContent>
      <div class="notifications-menu-wrapper">
        <header class="header">
          <div class="uds-paragraph tag">
            Notifications
            <span v-if="notificationsToRead.length > 0" class="uds-paragraph --small round-count">{{
              notificationsToRead.length
            }}</span>
          </div>
          <uds-tooltip tooltip-text="Tout marquer comme lu">
            <uds-action-button
              icon-name="drafts"
              size="small"
              :disabled="!(notificationStore.appNotifications.length > 0)"
              @click="notificationStore.markAsRead(true)"
            />
          </uds-tooltip>
        </header>

        <main class="notifications-content">
          <!-- NO NOTIFICATIONS -->
          <div v-if="!notificationStore.appNotifications.length" class="no-data">
            <img
              v-if="isComptalib()"
              src="~/assets/svg/notification-no-data-comptalib.svg"
              alt="No data"
            >
            <SvgNoDataNotificationsSvg v-else />
            <p class="uds-paragraph --small">Vous n’avez pas encore de notifications.</p>
          </div>

          <!-- WITH NOTIFICATIONS -->
          <div v-else class="notifications-block scrollbar">
            <NavbarNotificationItem
              v-for="notification in notificationStore.appNotifications"
              :key="notification.id"
              :notification="notification"
              @close="dropdown.isMenuShown = false"
            />
          </div>
        </main>
      </div>
    </template>
  </uds-simple-dropdown>
</template>

<script setup lang="ts">
const dropdown = ref<any>(null);
const { isComptalib } = useBrandsComposable();
const notificationStore = useNotificationStore();

const open = computed<boolean>(() => dropdown.value?.isMenuShown ?? false);

const primaryColors = computed(() => getPrimaryColors());
const udsColors = getUdsColors();

const notificationsToRead = computed(() =>
  notificationStore.appNotifications.filter((n) => !n.read),
);
</script>

<style scoped lang="scss">
.notifications-menu-wrapper {
  position: relative;
  max-width: 480px;
  height: 614px;
  background-color: $uds-white;

  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    padding: $uds-spacing-2 $uds-spacing-3;
    justify-content: space-between;
    border-bottom: 1px solid $uds-neutral-400;
    align-items: center;
    background-color: $uds-white;
    border-radius: $uds-radius-1 $uds-radius-1 0 0;

    .tag {
      display: flex;
      color: $uds-primary-500;
      width: fit-content;
      padding: $uds-spacing-1;
      background-color: $uds-primary-50;
      border-radius: 9999px;
      align-items: center;
      justify-content: space-between;

      .round-count {
        margin-left: $uds-spacing-1;
        width: 29px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: $uds-white;
        background-color: $uds-primary-500;
        border-radius: 9999px;
      }
    }
  }

  .notifications-content {
    margin-top: $uds-navbar-height;
    display: flex;
    flex-direction: column;
    .notifications-block {
      background-color: $uds-white;
    }

    .no-data {
      height: 544px;
      padding: 0 42px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.notifications-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  height: 40px;
  width: 40px;
  border-radius: 9999px;

  &:hover:not(.active) {
    background-color: $uds-neutral-300;
  }
  &.active {
    background-color: $uds-primary-50;
  }

  .round-count {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    font-size: 12px;
    width: 15px;
    height: 15px;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: $uds-white;
    padding: 2px;
    background-color: $uds-primary-500;
    border: 1px solid $uds-neutral-50;
    border-radius: 9999px;
  }
}
</style>
