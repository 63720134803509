<template>
  <div>
    <CommonsSimpleModal
      :is-open="displayModal"
      title="Import en cours..."
      content="Vos factures sont en train d'être analysées. Vous allez pouvoir bientôt les annoter !"
      :accept-closing="false"
      :reject-button-display="false"
      :accept-button-display="true"
      accept-button-text="Continuer"
      @close-accept="closeProcessModale"
      @close-reject="closeProcessModale"
    />
    <CommonsSimpleModal
      :is-open="displayErrorModale"
      title="Des documents n’ont pas pu être importés."
      :accept-closing="true"
      :reject-button-display="false"
      :accept-button-display="false"
      accept-button-text="Continuer"
      @close-accept="closeErrorModale()"
      @close-reject="closeErrorModale()"
    >
      <template #extra-content>
        <div class="content-block">
          <div class="content-text">
            Attention, les documents ci-dessous n’ont pas pu être importés. Cela peut être dû à une
            erreur de l’application, veuillez réessayer plus tard.
          </div>
          <div class="content-number-of-error-file">
            Document(s) non importé(s):
            {{ totalFilesInError.length }}/{{ annotateStore.totalFileToUpload }}
          </div>
          <div class="recap-block">
            <div v-for="(file, index) in totalFilesInError" :key="index" class="file">
              <uds-icon icon-name="cancel" :color="udsColors.udsError500" />
              <div class="file-name">{{ file.name }}</div>
            </div>
          </div>
        </div>
        <div class="cta">
          <uds-main-button @click="closeErrorModale()">Continuer</uds-main-button>
        </div>
      </template>
    </CommonsSimpleModal>
    <div v-if="activeTransaction">
      <CommonsModalsAdjustGlobalInvoices
        :key="dialogReloadCounter"
        v-model="showModalAdjust"
        :active-transaction="activeTransaction"
        :transactions="transactions"
        :active-transaction-index="activeTransactionIndex"
        :is-purchases="isPurchases"
        :is-expense-note="isExpenseNote"
        @previous-transaction="activeTransactionIndex = activeTransactionIndex - 1"
        @next-transaction="activeTransactionIndex = activeTransactionIndex + 1"
        @close="refreshAndRedirect"
        @replace-local-transaction="replaceLocalTransaction($event)"
        @delete-transaction="deleteTransaction($event)"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import type { ReadAccrualInvoice, TFile } from "@silexpert/core";
import { AccountingJournal } from "@silexpert/core";
import set from "lodash-es/set";
import cloneDeep from "lodash-es/cloneDeep";
const udsColors = getUdsColors();

const annotateStore = useAnnotateStore();
const societyStore = useSocietyStore();
const categoryStore = useCategoryStore();
const thirdPartyStore = useThirdPartiesStore();
const partnerStore = usePartnerStore();
const userStore = useUsersStore();

const idSociety = computed(() => societyStore?.society?.id ?? 0);

const dialogReloadCounter = ref(0);

const statusExtraction = computed(() => annotateStore.status);
const displayModal = computed<boolean>(() => {
  return statusExtraction.value === "pending";
});

const displayErrorModale = computed(
  () => annotateStore.displayErrorModale || annotateStore.forceDisplayErrorModale,
);

const totalFilesInError = computed(() => annotateStore.totalFilesInError ?? []);

const numberOfTransactions = computed<number>(() => transactions.value.length);

const filesInError = ref<{ name: string }[]>([]);
const numberTotalOfTransactions = computed<number>(
  () => numberOfTransactions.value + filesInError.value.length,
);

onMounted(async () => {
  // Fetch secondary data if they dont already exists
  await Promise.all([
    ...(categoryStore.categories.length === 0
      ? [categoryStore.fetchSocietyCategories(idSociety.value)]
      : []),
    ...(categoryStore.immobilizationCategories.length === 0
      ? [categoryStore.fetchImmobilizationCategories()]
      : []),
    ...(categoryStore.types.length === 0 ? [categoryStore.fetchCategoryTypes()] : []),
    ...(thirdPartyStore.allThirdParties.length === 0
      ? [thirdPartyStore.fetchThirdPartiesAndGenericThirdParties()]
      : []),
    ...(partnerStore.partners.length === 0 ? [partnerStore.fetchSocietyPartners()] : []),
  ]);

  window.$socket.on(
    `invoice-upload-process-finished-${idUser.value}-${idSociety.value}`,
    (interpretedInvoice: ReadAccrualInvoice[]) => {
      console.info(
        `socket reception invoice-upload-process-finished-${idUser.value}-${idSociety.value}`,
      );
      const clonedList = cloneDeep(toRaw(annotateStore.importedTransactions ?? []));
      const newList = [...clonedList, interpretedInvoice[0]];
      annotateStore.importedTransactions = newList;
      annotateStore.$patch({
        status: "finished",
      });
    },
  );
  window.$socket.on(
    `invoice-upload-process-finished-${idUser.value}-${idSociety.value}-error`,
    (interpretedInvoice: TFile[]) => {
      console.info(
        `socket reception invoice-upload-process-finished-${idUser.value}-${idSociety.value}-error`,
      );
      filesInError.value = filesInError.value.concat([
        { name: interpretedInvoice[0].originalname ?? "" },
      ]);
      annotateStore.$patch({
        totalFilesInError: filesInError.value,
      });
    },
  );
});

const idUser = computed(() => userStore.user!.id!);

const transactions = computed(() => {
  return annotateStore.importedTransactions ?? [];
});

const activeTransactionIndex = ref(0);

const isPurchases = computed(
  () => annotateStore.importedTransactions?.[0]?.idAccountingJournal === AccountingJournal.AC,
);

const isExpenseNote = computed(() => annotateStore.isExpenseNote);

const activeTransaction = computed(() => {
  return transactions.value[activeTransactionIndex.value];
});

const hasTransactions = computed(() => transactions.value.length > 0);

const showModalAdjust = computed(() => {
  return hasTransactions.value && !!activeTransaction.value;
});

function replaceLocalTransaction(transaction: ReadAccrualInvoice) {
  const transactionId = transaction.id;
  const indexOfTransactionToReplace = transactions.value.findIndex(
    (t: ReadAccrualInvoice) => t.id === transactionId,
  );
  set(transactions.value, indexOfTransactionToReplace, transaction);
}

async function deleteTransaction(transactionId: number) {
  // check if the active index is still in the transaction array
  const newLength = transactions.value.length - 1;
  const isIndexStillPresent = activeTransactionIndex.value <= newLength - 1;
  if (!isIndexStillPresent) {
    activeTransactionIndex.value = newLength - 1;
  }

  const indexOfTransactionToReplace = transactions.value.findIndex(
    (t: ReadAccrualInvoice) => t.id === transactionId,
  );
  transactions.value.splice(indexOfTransactionToReplace, 1);

  const societyId: number = societyStore.society!.id!;

  await $silex()
    .transaction.delete(societyId, transactionId)
    .then(() => {
      $notifier().open({
        type: "success",
        content: "Document supprimé avec succès",
      });
    })
    .catch((error: any) => {
      $notifier().open({ type: "error", content: apiErrorToString(error) });
    });

  if (newLength <= 0) {
    refreshAndRedirect();
  }
}

async function refreshAndRedirect() {
  if (isPurchases.value) {
    await navigateTo("/purchases#forceRefresh");
  } else if (isExpenseNote.value) {
    await navigateTo("/purchases/expense-notes#forceRefresh");
  } else {
    await navigateTo("/sales?tab=%22Factures%22#forceRefresh");
  }

  annotateStore.reset();
}

function closeProcessModale() {
  annotateStore.$patch({
    status: "finished",
  });
}

watch(numberTotalOfTransactions, (value) => {
  const totalFiles = annotateStore.totalFileToUpload;
  const totalFilesInError = annotateStore.totalFilesInError ?? [];
  if (value === totalFiles) {
    if (totalFilesInError.length > 0) {
      annotateStore.$patch({
        displayErrorModale: true,
      });
    }
  }
});

function closeErrorModale() {
  annotateStore.$patch({
    displayErrorModale: false,
    forceDisplayErrorModale: false,
    totalFileToUpload: 0,
    totalFilesInError: null,
  });
}
</script>

<style lang="scss">
.annotate-container,
.test {
  width: 100vw;
  height: 100vh;
  display: flex;
}

.content-block {
  color: $uds-neutral-900;
  font-size: 16px;
  font-style: normal;
  font-weight: $uds-weight-450;

  .content-text {
    line-height: 140%;
    margin-bottom: $uds-spacing-2;
  }

  .content-number-of-error-file {
    color: $uds-error-500;
  }

  .recap-block {
    border-radius: $uds-spacing-0_5;
    border: 1px solid $uds-neutral-400;
    display: flex;

    padding: 16px 16px 76px 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    margin: 8px 0 16px 0;

    .file {
      display: flex;
      height: 22px;
      align-items: center;
      gap: 8px;
      align-self: stretch;
    }
  }
}
.cta {
  display: flex;
  width: 100%;
  justify-content: center;
}
</style>
