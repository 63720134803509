<template>
  <div class="help-menu-container">
    <div v-if="wantTakeAppointment" class="help-menu__header">
      <div class="help-menu__back">
        <div class="back-icon" @click="wantTakeAppointment = false">
          <uds-icon icon-name="arrow_back" :color="primaryColor" />
        </div>
        <span class="help-menu__text--title">Prendre rendez-vous avec mon comptable.</span>
      </div>
    </div>
    <div v-else class="help-menu__header">
      <span class="help-menu__text--title">Besoin d’aide ?</span>
      <span class="help-menu__text--subtitle">Nous sommes là pour vous accompagner.</span>
    </div>

    <div v-if="wantTakeAppointment && accountant" class="help-menu__body">
      <div class="help-menu__list">
        <span
          >Le comptable est votre interlocuteur privilégié pour les questions liées à la
          comptabilité uniquement.</span
        >

        <div class="help-menu__accountant">
          <img class="accountant__picture" :src="pictureAccountant" >
          <div class="accountant__identity">
            <span class="accountant__identity--name"
              >{{ accountant.firstname }} {{ accountant.lastname }}</span
            >
            <span class="accountant__identity--email">{{ accountant.email }}</span>
            <span class="accountant__identity--phone">{{ phone }}</span>
            <span class="accountant__identity--working-schedules">
              Mes horaires:
              <CommonsTextTooltipIfTextOverflowed :text="workingSchedules" />
            </span>
          </div>
        </div>
      </div>
      <div class="help-menu__footer">
        <div
          class="help-menu__contact help-menu__action"
          style="justify-content: center"
          @click="openCalendlyAppointment()"
        >
          <uds-icon icon-name="calendar_today" :color="primaryColor" />
          <span style="margin-left: 10px">Prendre rendez-vous</span>
        </div>
        <span class="help-menu__info">
          Un problème technique ?
          <span class="info-link" @click="openCrisp">Discutez avec le chat<span/> </span
        ></span>
      </div>
    </div>

    <div v-else class="help-menu__body">
      <div class="help-menu__list">
        <div class="help-menu__item help-menu__action" @click="openHelpLink">
          <div class="help-menu__item-title">
            <uds-icon icon-name="help_outline" :color="udsColors.udsNeutral800" />
            <span>Consulter le centre d’aide</span>
          </div>
          <uds-icon icon-name="open_in_new" :color="udsColors.udsNeutral800" />
        </div>

        <div class="help-menu__item help-menu__action" @click="openCrisp">
          <div class="help-menu__item-title">
            <uds-icon icon-name="chat" :color="udsColors.udsNeutral800" />
            <span>Discuter avec le chat</span>
          </div>
          <uds-icon icon-name="chevron_right" :color="udsColors.udsNeutral800" />
        </div>

        <div v-if="isComptalib()" class="help-menu__item help-menu__action" @click="openFbGroup">
          <div class="help-menu__item-title">
            <uds-icon icon-name="people" :color="udsColors.udsNeutral800" />
            <span> Échanger avec la communauté</span>
          </div>
          <uds-icon icon-name="open_in_new" :color="udsColors.udsNeutral800" />
        </div>

        <div
          v-if="!isComptalib() && hasDedicatedAccountant && shouldDisplayAccountantAppointment"
          class="help-menu__item help-menu__action"
          @click="openTakeAppointment"
        >
          <div class="help-menu__item-title">
            <uds-icon icon-name="work" :color="udsColors.udsNeutral800" />
            <span>Prendre rendez-vous avec mon comptable</span>
          </div>
          <uds-icon icon-name="chevron_right" :color="udsColors.udsNeutral800" />
        </div>
      </div>

      <div class="help-menu__footer">
        <div v-if="!isComptalib()" class="help-menu__contact contact-caller">
          <uds-icon icon-name="call" :color="primaryColor" />
          <span>03 83 93 14 14</span>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_2512_398"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="24"
              height="24"
            >
              <circle cx="12" cy="12" r="12" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_2512_398)">
              <rect width="8" height="24" fill="#002395" />
              <rect x="8" width="8" height="24" fill="white" />
              <rect x="16" width="8" height="24" fill="#ED2939" />
            </g>
          </svg>
          <span>Gratuit</span>
        </div>
        <span class="help-menu__info">
          Notre équipe est disponible du lundi au vendredi de 9h à {{ endHour }}.
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Lab, getHoraires } from "@silexpert/core";
import { hostS3 } from "~/utils/link";
const { isComptalib } = useBrandsComposable();

const accountantComposable = useAccountantComposable();
const societyStore = useSocietyStore();
const wantTakeAppointment = ref<boolean>(false);

const brands = useBrandsComposable();

const accountant = computed(() => societyStore.accountant);
const hasDedicatedAccountant = computed(() => isDefined(accountant.value));
const workingSchedules = computed<string>(() =>
  hasDedicatedAccountant.value ? getHoraires(accountant.value!) : "",
);

const endHour = computed(() => (brands.isPourcentage() ? "18h" : "19h"));

const primaryColor = computed(() => {
  return getPrimaryColors().primary500;
});
const udsColors = getUdsColors();

const pictureAccountant = computed<string>(() => {
  const token = accountant?.value?.avatar?.token;
  const idAccountant = accountant?.value?.id;
  return `${hostS3}users/${idAccountant}/${token}`;
});

const phone = computed<string>(() => {
  const phone = accountant?.value?.phone ?? accountant?.value?.phone2;
  if (phone) {
    return `${phone.substring(0, 2)} ${phone.substring(2, 4)} ${phone.substring(
      4,
      6,
    )} ${phone.substring(6, 8)} ${phone.substring(8, 10)}`;
  }
  return "";
});

const shouldDisplayAccountantAppointment = computed(
  () =>
    !isComptalib() &&
    isDefined(societyStore.accountant?.id) &&
    isDefined(societyStore.detail?.acceptation) &&
    societyStore.detail?.acceptation === Lab.ACCEPTED,
);

function openTakeAppointment() {
  if (!isComptalib()) {
    wantTakeAppointment.value = true;
  }
}

function openCalendlyAppointment() {
  accountantComposable.openCalendlyAppointment({ withoutType: true });
}

function openCrisp() {
  if (window?.$crisp) {
    window.$crisp.push(["do", "chat:open"]);
  }
}

function openHelpLink() {
  const url = isComptalib()
    ? "https://help.comptalib.com/fr/"
    : "https://www.compta-clementine.fr/aide/";

  window.open(url, "_blank");
}

function openFbGroup() {
  window.open("https://www.facebook.com/groups/616334702397087", "_blank");
}
</script>

<style lang="scss" scoped>
.help-menu-container {
  cursor: default;
  width: inherit;

  box-shadow:
    0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: $uds-spacing-1;

  min-width: 400px;
  min-height: 363px;
  overflow-y: visible;

  background: $uds-white;
}

.help-menu__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: $uds-spacing-2;
  background-color: $uds-primary-500;
  color: $uds-white;
  height: 70px;

  .help-menu__back {
    display: flex;

    .back-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $uds-spacing-3;
      height: $uds-spacing-3;
      border-radius: 100%;
      margin-right: 15px;
      background-color: $uds-white;
      cursor: pointer;
    }
  }

  .help-menu__text {
    &--title {
      font-weight: $uds-weight-500;
      font-size: 16px;
    }
    &--subtitle {
      font-size: 14px;
    }
  }
}

.help-menu__body {
  padding: $uds-spacing-2;

  .help-menu__action {
    cursor: pointer;
  }

  .help-menu__list {
    margin-bottom: $uds-spacing-3;

    .help-menu__accountant {
      display: flex;
      flex-direction: row;
      margin-top: 20px;

      .accountant__picture {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 81px;
        width: 81px;
        border-radius: 100%;
      }
      .accountant__identity {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: $uds-spacing-2;
        &--name {
          color: $uds-primary-500;
          font-weight: $uds-weight-500;
          font-size: 16px;
        }
        &--email {
          font-size: 14px;
          color: $uds-neutral-900;
        }

        &--phone {
          font-size: 14px;
          color: $uds-neutral-900;
        }

        &--working-schedules {
          font-size: 14px;
          color: $uds-neutral-800;
          max-width: 370px;
        }
      }
    }
  }

  .help-menu__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 42px;
    margin-bottom: $uds-spacing-1;
    border-radius: 5px;
    padding: 9px $uds-spacing-1;
    background-color: $uds-neutral-100;

    .help-menu__item-title {
      display: flex;
      align-items: center;

      span {
        margin-left: 10px;
      }
    }
  }

  .help-menu__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    .help-menu__contact {
      display: flex;
      align-items: center;
      width: 260px;
      height: 40px;
      border-radius: $uds-spacing-1;
      background-color: $uds-primary-50;
      font-size: 16px;

      &.contact-caller {
        justify-content: space-evenly;
      }

      span {
        color: $uds-primary-500;
      }

      .icon-phone {
        color: $uds-primary-500;
      }
    }
    .help-menu__info {
      margin-top: $uds-spacing-1;
      text-align: center;
      width: 85%;

      .info-link {
        color: $uds-primary-500;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
</style>
