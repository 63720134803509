<template>
  <div class="accompaniement-wrapper">
    <div class="accompaniement-container">
      <div class="uds-headers --h4">Mon accompagnement {{ brands.getBrandName() }}</div>
      <div class="illustrations">
        <img class="img-photos" :src="`${hostS3}appointment.svg`" alt="logo" height="40px" >
        <SvgArrowRoundedDownSvg height="50px" />
      </div>
      <p class="uds-paragraph --small">Pour prendre RDV avec nos équipes, c'est par ici !</p>
      <div class="--cta">
        <uds-main-button
          type="secondary"
          size="medium"
          icon-name="calendar_today"
          @click="openCalendlyAppointment()"
        >
          Prendre RDV
        </uds-main-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const brands = useBrandsComposable();
const accountantComposable = useAccountantComposable();

const hostS3 = brands.getClientBrandConfig().urlS3;

function openCalendlyAppointment() {
  accountantComposable.openCalendlyAppointment({ withoutType: true });
}
</script>

<style lang="scss" scoped>
.accompaniement-wrapper {
  padding: $uds-spacing-2;
  .accompaniement-container {
    margin-top: $uds-spacing-2;
    border-radius: $uds-radius-1;
    background: $uds-white;
    box-shadow: 0px 4px 8px 0px rgba(51, 51, 51, 0.08);
    padding: $uds-spacing-2;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $uds-spacing-2;

    .illustrations {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: $uds-spacing-1_5;
      & > svg {
        padding-top: $uds-spacing-1;
      }
    }

    p.uds-paragraph.--small {
      margin-top: -$uds-spacing-2;
      margin-bottom: 0px;
    }

    .--cta {
      width: 100%;
      &:deep(button) {
        width: 100%;
      }
    }
  }
}
</style>
