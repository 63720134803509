<template>
  <CommonsModalsModalCustom
    :svg="svg"
    width="775px"
    height="auto"
    :hide-close-icon="canCloseTheModal ? false : true"
    :disable-click-outside="canCloseTheModal ? false : true"
  >
    <template #content>
      <div id="unpaid-dialog" class="scrollbar" style="display: flex; flex-direction: column">
        <div class="unpaid-header">
          <SvgUnpaidIllustrationSvg />
        </div>
        <div class="unpaid-body">
          <div class="form-row">
            <div class="uds-headers --h2">Vous avez un retard de paiement</div>
          </div>

          <div class="uds-paragraph description">
            Pour débloquer l’application et assurer la bonne réalisation des travaux comptables,
            merci de régulariser vos exercices impayés :
          </div>

          <div class="total-amount">
            <div class="uds-paragraph">
              Total dû : <uds-amount-currencie :value="totalAmount" :has-symbol="false" />
              <uds-main-button
                size="small"
                type="secondary"
                icon-name="sync"
                :loading="isRefreshing"
                @click="refresh()"
                >Actualiser</uds-main-button
              >
            </div>
          </div>

          <div class="exercices">
            <div
              v-for="(period, index) in unpaidDetails"
              :key="`ud-${index}`"
              class="unpaid-exercice"
            >
              <div>
                <CommonsInputsSelectorButton
                  :items="
                    invoices
                      .filter((i) =>
                        dayjs(i.date).isBetween(period.startDate, period.endDate, 'd', '[]'),
                      )
                      .map((i) => ({
                        date: capitalizeFirstLetter(`${dayjs(i.date).format('MMMM YYYY')}`),
                        url: i.url,
                      }))
                  "
                  icon="download"
                  :is-loading="isFetchingInvoices"
                  item-text="date"
                  item-value="url"
                  @updated="downloadFile($event)"
                  @click="
                    fetchInvoices(
                      period.startDate as unknown as Date,
                      period.endDate as unknown as Date,
                    )
                  "
                />
              </div>
              <div>
                Exercice du
                {{ dayjs(period.startDate).format("DD/MM/YYYY") }} au
                {{ dayjs(period.endDate).format("DD/MM/YYYY") }} :
              </div>
              <uds-amount-currencie
                :value="period.amount"
                :has-symbol="false"
                style="display: inline"
              />
              <div class="action-pay" @click="createPayment(period.amount)">
                Payer cet exercice <uds-icon icon-name="arrow_right_alt" />
              </div>
            </div>
          </div>

          <uds-banner>
            <template #content>
              <div class="unpaid-banner-content">
                <div>
                  <uds-icon icon-name="info" :color="getPrimaryColors().primary500" size="medium" />
                </div>
                <div>
                  La durée de suspension de nos travaux reportera d’autant le délai de livraison de
                  vos échéances fiscales. <b>Passé 3 mois de retard,</b> nous nous réservons le
                  droit de résilier le contrat et les sommes perçues sont acquises.
                </div>
              </div>
            </template>
          </uds-banner>
        </div>

        <div class="unpaid-footer">
          <uds-main-button type="secondary" size="small" @click="openContactDialog()">
            Contacter le service facturation
          </uds-main-button>
          <uds-main-button
            :loading="isLoading"
            :disabled="false"
            size="small"
            @click="createPayment()"
            >Payer le total</uds-main-button
          >
        </div>
      </div>
    </template>
  </CommonsModalsModalCustom>
</template>

<script setup lang="ts">
import type { ReadPaymentInvoice } from "@silexpert/core";
import {
  PaymentReason,
  RoleAllowedToLogInAsClient,
  VisualizedInformationState,
} from "@silexpert/core";
import { useModal } from "vue-final-modal";
import UnpaidContactModal from "./UnpaidContactModal.vue";

defineEmits(["cancel", "close"]);

const dayjs = useDayjs();
const societyStore = useSocietyStore();
const exerciceStore = useExerciceStore();
const roleComposable = useRolesComposable();
const brandComposable = useBrandsComposable();

defineProps<{
  svg?: string;
}>();

const isLoading = ref(false);
const hasClickedOnPay = ref(false);
const isRefreshing = ref(false);
const isFetchingInvoices = ref(false);

const invoices = ref<Array<ReadPaymentInvoice>>([]);
const fetchedExercicesList = ref<number[]>([]);

const unpaidDetails = computed(() => societyStore.unpaidPeriod ?? []);

const currentVisualizedInformation = computed(() =>
  societyStore.visualizedInformations?.find((vi) => vi.key === "allowed_to_close_unpaid"),
);

const hasVisualizedInformationForBypass = computed<boolean>(() => {
  if (
    currentVisualizedInformation.value?.state === VisualizedInformationState.APPROVED &&
    isDefined(currentVisualizedInformation.value?.updatedAt)
  ) {
    return dayjs(dayjs()).diff(currentVisualizedInformation.value.updatedAt, "days") < 15;
  }
  return false;
});

const canCloseTheModal = computed(() => {
  return (
    roleComposable.hasOneOfTheseRoles(RoleAllowedToLogInAsClient) ||
    hasVisualizedInformationForBypass.value
  );
});

async function fetchInvoices(startDate: Date, endDate: Date) {
  try {
    isFetchingInvoices.value = true;
    const exercice = exerciceStore.exercices.find(
      (e) => e.startDate === startDate && e.endDate === endDate,
    );
    const idExercice = exercice!.id!;
    const index = fetchedExercicesList.value.findIndex((e) => e === idExercice);
    if (index > -1) return;
    const result = await $silex().payment.getAllInvoices(idExercice, { unpaid: true });
    invoices.value.push(...result);
    fetchedExercicesList.value.push(idExercice);
  } catch (error) {
    $notifier().open({ content: apiErrorToString(error) });
  } finally {
    isFetchingInvoices.value = false;
  }
}

async function refresh(): Promise<void> {
  isRefreshing.value = true;
  await societyStore.fetchSociety();
  await navigateTo(`${useRoute().fullPath}#forceRefresh`);
  isRefreshing.value = false;
}

function downloadFile(url: string): void {
  try {
    window.open(url, "_blank");
  } catch {
    $notifier().open({ content: "Une erreur est survenue lors du téléchargement" });
  }
}

const totalAmount = computed<number>(() =>
  unpaidDetails.value.reduce((acc, value) => acc + value.amount, 0),
);

async function createPayment(amount?: number | null) {
  isLoading.value = true;
  hasClickedOnPay.value = true;
  try {
    const payment = await $silex().paymentExpectation.create(societyStore.society!.id!, {
      amount: amount ? Math.round(amount * 100) : totalAmount.value * 100,
      label: `Paiement impayé`,
      idReason: PaymentReason.UNPAID,
    });

    let redirection: string;
    if (brandComposable.isComptaStart()) {
      // Cas exceptionnel: redirige vers le site de clementine
      redirection = `https://www.clementine.fr/PaymentsWaiting/pageDePaiement?ref=${payment.ref}&type=legalstart`;
    } else {
      redirection = `${brandComposable.getClientBrandConfig(societyStore.society!.idTypeBrand ?? 1).urlClient}/PaymentsWaiting/pageDePaiement?ref=${payment.ref}`;
    }
    setTimeout(() => {
      window.open(redirection, "_blank");
    }, 100);
  } finally {
    isLoading.value = false;
  }
}

async function openContactDialog() {
  const { open, close } = useModal({
    component: UnpaidContactModal,
    attrs: {
      onCancel() {
        close();
      },
      onClose() {
        close();
      },
    },
  });
  await open();
}
</script>

<style lang="scss" scoped>
#unpaid-dialog {
  max-height: inherit;
  border-radius: 5px;
}

.unpaid-header {
  background: linear-gradient(180deg, $uds-primary-50 0%, $uds-white 63.87%);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  &:deep(svg) {
    padding: $uds-spacing-3;
    width: calc(100% - $uds-spacing-3 * 2);
  }
}

.unpaid-body {
  padding: $uds-spacing-4;
  .description {
    margin-top: $uds-spacing-2;
    margin-bottom: $uds-spacing-2;
  }
  .total-amount {
    margin-top: $uds-spacing-1;
    margin-bottom: $uds-spacing-1;
    & > div {
      display: flex;
      align-items: center;
      :deep(.uds-amounts) {
        font-weight: 700;
        span:not(.--decimal) {
          font-size: 18px;
        }
        span {
          font-size: 16px;
        }
      }
      :deep(button) {
        margin-left: $uds-spacing-2;
      }
    }
  }

  :deep(.banner-container) {
    .unpaid-banner-content {
      display: flex;
      flex-direction: row;
      gap: $uds-spacing-0_5;
    }
  }

  .exercices {
    display: flex;
    flex-direction: column;
    gap: $uds-spacing-1;

    .action-pay {
      display: flex;
      align-items: center;
      color: $uds-primary-500;
      font-size: 16px;
      gap: $uds-spacing-1;
      cursor: pointer;
      &:hover {
        gap: $uds-spacing-1_5;
        transition: 0.2s;
      }
    }

    .unpaid-exercice {
      border: 1px solid $uds-neutral-400;
      padding: $uds-spacing-1 $uds-spacing-2;
      border-radius: $uds-radius-1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: $uds-spacing-1;
      & > div:nth-child(3) {
        width: 100px;
        text-align: right;
        padding-right: $uds-spacing-2;
        padding-left: 0;
      }
      :deep(.uds-amounts) {
        font-weight: 700;
      }
    }
  }
}

.unpaid-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: $uds-spacing-1_5;
  padding-left: $uds-spacing-4;
  padding-right: $uds-spacing-4;
  padding-bottom: $uds-spacing-2;
}
</style>
