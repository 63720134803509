<template>
  <CommonsModalsModalBody height="auto">
    <template #title>Découvrez l'application {{ brand }} sur mobile</template>
    <template #content>
      <div class="qrcode-container">
        <span v-if="!isComptaStart()"> Scannez le code QR pour télécharger l'application : </span>

        <div v-if="!isComptaStart()" class="qrcode-img">
          <img :src="urlQrCodeImg" >
        </div>

        <span>
          {{ !isComptaStart() ? "ou rendez-vous " : "Rendez-vous" }} directement sur votre magasin
          d'applications
        </span>

        <div class="store-img">
          <NuxtLink :href="appStoreLink" target="_blank">
            <img src="~/assets/img/mobileInfo/AppStoreButton.svg" >
          </NuxtLink>
          <NuxtLink :href="googlePlayLink" target="_blank">
            <img src="~/assets/img/mobileInfo/GooglePlayButton.svg" >
          </NuxtLink>
        </div>
      </div>
    </template>
  </CommonsModalsModalBody>
</template>

<script setup lang="ts">
import { filename } from "pathe/utils";

defineEmits(["close"]);

const { getBrandName, isComptaStart, isClementine } = useBrandsComposable();
const brand = getBrandName();

const glob = import.meta.glob("@/assets/img/mobileInfo/qrcodes/*.svg", { eager: true });
const images = Object.fromEntries(
  Object.entries(glob).map(([key, value]: [string, any]) => [filename(key), value.default]),
);
const urlQrCodeImg = computed<string>(() => images[`qrcode_${brand.toLowerCase()}`]);

const googlePlayLink = computed<string>(() => {
  const brandName = isClementine() ? "myclementine" : brand.toLowerCase();
  return `https://play.google.com/store/apps/details?id=com.clementine.${brandName}`;
});

const appStoreLinks: Record<string, string> = {
  Comptalib: "https://apps.apple.com/fr/app/comptalib/id1532091895/",
  Clementine: "https://apps.apple.com/fr/app/clementine/id1119493780/",
  Pourcentage: "https://apps.apple.com/fr/app/pourcentage-fr/id1631156043",
};

const appStoreLink = computed<string>(() => {
  return appStoreLinks[brand] ?? "";
});
</script>

<style lang="scss" scoped>
// .modal-body {
//   &.comptastart {
//     min-height: fit-content;
//   }
// }

.qrcode-container {
  color: $uds-neutral-900;
  text-align: center;
  font-weight: $uds-weight-450;
  font-size: 16px;

  .qrcode-img {
    height: 240px;
    margin-top: $uds-spacing-3;
    margin-bottom: $uds-spacing-3;
  }

  .store-img {
    display: flex;
    gap: $uds-spacing-2;
    justify-content: center;
    margin-top: $uds-spacing-3;

    img {
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
    }
  }
}

:deep(.modal-title) {
  font-size: 32px;
  font-weight: $uds-weight-700;
  line-height: 120%;
  padding: $uds-spacing-4;
  padding-bottom: 0;
}

:deep(.modal-content) {
  padding: $uds-spacing-4;
  margin: 0;
}

:deep(.modal-inner) {
  margin: 0;
}

:deep(.modal-footer) {
  padding: 0;
}
</style>
