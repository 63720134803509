<template>
  <div/>
</template>

<script setup lang="ts">
import { useModal } from "vue-final-modal";
import ComptalibSubscriptionErrorModal from "../commons/modals/ComptalibSubscriptionErrorModal.vue";

const subscriptionStore = useSubscriptionStore();

const isDialogOpened = computed(() => subscriptionStore.isInErrorState);

watch(
  () => isDialogOpened.value,
  () => (isDialogOpened.value ? show() : null),
);

onMounted(() => (isDialogOpened.value ? show() : null));

async function show() {
  const { open, close } = useModal({
    component: ComptalibSubscriptionErrorModal,
    attrs: {
      onCancel() {
        close();
      },
      onClose() {
        close();
      },
    },
  });
  await open();
}
</script>
