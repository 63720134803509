<template>
  <div id="informations-needed-bar" :class="`statut-${statusClass}`" class="scrollbar">
    <template v-if="statusClass === 'refused'">
      <p class="uds-paragraph --small">
        {{ refusedMessage }}
      </p>
      <div class="end-button" @click="goToComplete()">Envoyer les documents</div>
    </template>
    <template v-if="statusClass === 'done'">
      <p class="uds-paragraph --small">
        Félicitations ! Tous vos documents ont été validés par notre équipe.
      </p>
      <div class="end-button" @click="hideBanner">OK</div>
    </template>
    <template v-if="statusClass === 'infos'">
      <p class="uds-paragraph --small">
        Bienvenue sur {{ brandName }} ! Nous avons besoin d’informations supplémentaires pour
        compléter votre dossier.
      </p>
      <div class="end-button" @click="goToComplete()">Finaliser mon dossier</div>
    </template>
  </div>
</template>

<script setup lang="ts">
const { getBrandName } = useBrandsComposable();
const brandName = getBrandName();

defineProps<{
  statusClass: "refused" | "done" | "infos" | null;
}>();

const societyStore = useSocietyStore();
const libraryStore = useLibraryStore();
const nbFilesRefused = computed(() => {
  const count = libraryStore.legalDocuments?.filter((document) => document.statutFile === 0) ?? [];
  return count.length;
});

const refusedMessage = ref<string>(`
  ${nbFilesRefused.value} document${nbFilesRefused.value > 1 ? "s" : ""}
        ${nbFilesRefused.value > 1 ? "ont" : "a"} été refusé${nbFilesRefused.value > 1 ? "s" : ""} par
        notre équipe, veuillez ${nbFilesRefused.value > 1 ? "les " : "l'"}envoyer à nouveau${nbFilesRefused.value > 1 ? "x" : ""}.
`);

async function hideBanner() {
  try {
    await $silex().society.update(societyStore.society?.id ?? -1, {
      society: { hideInformationsBanner: true },
    });
    await societyStore.fetchSociety(societyStore.society?.id);
  } catch (error) {
    $notifier().open({ type: "error", content: apiErrorToString(error) });
  }
}

function goToComplete() {
  return navigateTo("/folder/complete");
}
</script>

<style lang="scss" scoped>
#informations-needed-bar {
  display: flex;
  position: fixed;
  height: 50px;
  top: 0;
  left: 0px;
  z-index: 10;
  width: 100%;

  justify-content: center;
  align-content: center;
  align-items: center;

  p {
    color: $uds-white;
  }
}

.end-button {
  position: relative;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 28px;
  padding: 0px $uds-spacing-1;
  border-radius: 9999px;
  font-size: 14px;
  background-color: $uds-neutral-50;

  &:hover:not(.active) {
    background-color: $uds-neutral-300;
  }
  &.active {
    background-color: $uds-primary-50;

    .help-text {
      color: $uds-primary-500 !important;
    }
  }
}

.statut-done {
  background-color: $uds-success-500;
}
.statut-refused {
  background-color: $uds-error-300;
}
.statut-infos {
  background-color: $uds-google-blue;
}
</style>
