<template>
  <div>
    <CommonsSubscriptionBar v-if="displaySubscriptionBar" />
    <CommonsInformationsNeededBar
      v-if="displayInformationsNeededBar && canViewInformationsNeededBar"
      :status-class="societyLegalDocumentsStatus"
    />
    <div
      class="default_layout_container"
      :class="{ '--toggle-drawer': toggleDrawer }"
      :style="hasTopBarDisplayed ? 'padding-top: 50px;' : ''"
    >
      <ModalsContainer />

      <CommonsNotifier />
      <NuxtPwaManifest />

      <CommonsModalsStatusExtrationModal :from-revision="false" />

      <CommonsModalsHandleGlobalImport />

      <LayoutMainNavbar class="navbar" @toggle-drawer="toggleDrawer = !toggleDrawer" />

      <div class="drawer scrollbar" :class="{ '--top-bar-displayed': hasTopBarDisplayed }">
        <LayoutDrawer :is-admin="false" :toggle="toggleDrawer" />
      </div>

      <div class="content" :class="{ '--top-bar-displayed': hasTopBarDisplayed }">
        <slot />
      </div>
    </div>

    <!-- Modal cgu -->
    <LayoutCguModal />

    <!-- Popup show mobile infos -->
    <LayoutMobileInfosPopup v-if="showMobileInfoPopup" @close="showMobileInfoPopup = false" />

    <!-- Freemium -->
    <LayoutRestrictionModal />

    <!-- Impayé / Méthode de paiement expirée -->
    <LayoutComptalibUnpaidModal v-if="brandComposable.isComptalib()" />
    <LayoutUnpaidModal v-else />
    <LayoutNewFeaturesPopup />
  </div>
</template>

<script setup lang="ts">
import { SubscriptionType, LegalFormTypeEnum, GedFileType, Role } from "@silexpert/core";
import { ModalsContainer } from "vue-final-modal";

const { isApple } = useDevice();

useHead({
  bodyAttrs: {
    class: isApple ? "" : "designed-scrollbar",
  },
});

const userStore = useUsersStore();
const brandComposable = useBrandsComposable();

try {
  if (window?.$crisp) {
    window.$crisp.push(["do", "chat:show"]);
    window?.$crisp?.push(["set", "user:email", userStore.user?.email]);
    window.$crisp.push(["config", "position:reverse", [true]]);
  }
} catch (error) {
  console.error(error);
}

const dayjs = useDayjs();

const toggleDrawer = ref(false);
const societyStore = useSocietyStore();
const libraryStore = useLibraryStore();

await libraryStore.fetchLegalDocuments();
const isLoading = ref(false);

const displaySubscriptionBar = computed(() => {
  if (!societyStore.society) return false;

  return societyStore.society?.idSubscriptionType === SubscriptionType.FREEMIUM;
});

const societyLegalDocumentsStatus = computed(() => {
  if (!societyStore.society) return null;

  const filesRefused =
    libraryStore.legalDocuments?.filter((document) => document.statutFile === 0) ?? [];

  if (filesRefused.length) return "refused";

  const filesWaiting =
    libraryStore.legalDocuments?.filter((document) => document.statutFile === null) ?? [];
  const infosCompleted = societyStore.society.folderIsFinished;
  const isSocietyMicro = [LegalFormTypeEnum.EI, LegalFormTypeEnum.AUTO_ENTREPRENEUR].includes(
    societyStore.society.idLegalForm ?? -1,
  );

  // Check if all necessary documents types are uploaded
  const statutFiles =
    libraryStore.legalDocuments?.filter((f) => f.idTypeGedFile === GedFileType.STATUTES) ?? [];
  const cniFiles =
    libraryStore.legalDocuments?.filter((f) => f.idTypeGedFile === GedFileType.IDENTITY_CARD) ?? [];
  const kbisFiles =
    libraryStore.legalDocuments?.filter((f) => f.idTypeGedFile === GedFileType.KBIS) ?? [];
  const ribFiles =
    libraryStore.legalDocuments?.filter((f) => f.idTypeGedFile === GedFileType.RIB) ?? [];

  const allNeededFilesAreUploaded =
    (statutFiles?.length > 0 || isSocietyMicro) &&
    cniFiles?.length > 0 &&
    (kbisFiles?.length > 0 || isSocietyMicro) &&
    ribFiles?.length > 0;

  if (infosCompleted && !filesWaiting.length && allNeededFilesAreUploaded) return "done";

  if (infosCompleted && filesWaiting.length && allNeededFilesAreUploaded) return null;

  return "infos";
});

const displayInformationsNeededBar = computed(() => {
  if (!societyStore.society) return false;

  const isCreatedAfter = dayjs(societyStore.society.createdAt).isAfter(dayjs("2020-03-02"));
  const isPremium = societyStore.society.idSubscriptionType === SubscriptionType.PREMIUM;
  const userHideBanner = societyStore.society.hideInformationsBanner !== true;
  const condition = isCreatedAfter && isPremium && userHideBanner;

  return condition && !isLoading.value && societyLegalDocumentsStatus.value !== null;
});

const prestationComposable = usePrestationsComposable();
const visualizedInformationKey = VisualizedInformationKey.MOBILE_APP_BUBBLE;
const canViewInformationsNeededBar = computed(() => {
  return (
    brandComposable.hasOneOfTheseBrands([
      EBrandNames.CLEMENTINE,
      EBrandNames.POURCENTAGE,
      EBrandNames.COMPTASTART,
    ]) && prestationComposable.hasSignedPaidAccountingPrestation()
  );
});

/**
 * Show popup to inform the user of the mobile app
 */
const roleComposable = useRolesComposable();
const showMobileInfoPopup = ref(false);
function setPopupMobile() {
  // Check if the user has already close the popup
  const societyHidePopup = societyStore.visualizedInformations?.find(
    (info) => info.key === visualizedInformationKey,
  );
  if (societyHidePopup) return false;

  // Check if this is the first time the user connects
  if (!isDefined(userStore.user?.beforeLastConnection)) return false;

  const hideMobileAppBubbleDate = localStorage.getItem("hideMobileAppBubbleDate");
  if (hideMobileAppBubbleDate) {
    const hideDate = dayjs(hideMobileAppBubbleDate);
    if (hideDate.isAfter(dayjs())) return false;
    localStorage.removeItem("hideMobileAppBubbleDate");
  }

  return (
    roleComposable.hasOneOfTheseRoles([Role.CLIENT]) &&
    brandComposable.hasOneOfTheseBrands([
      EBrandNames.CLEMENTINE,
      EBrandNames.COMPTASTART,
      EBrandNames.POURCENTAGE,
      EBrandNames.COMPTALIB,
    ])
  );
}

showMobileInfoPopup.value = setPopupMobile();

const hasTopBarDisplayed = computed(
  () =>
    displaySubscriptionBar.value ||
    (displayInformationsNeededBar.value && canViewInformationsNeededBar.value),
);
</script>

<style lang="scss" scoped>
.default_layout_container {
  display: grid;
  transition: 300ms;
  grid-template-columns: 250px 2fr 2fr;
  grid-template-rows: repeat(2, auto);
  grid-template-areas:
    "navbar navbar navbar"
    "drawer content content";
  transition: 100ms;
  .navbar {
    grid-area: navbar;
  }

  .drawer {
    grid-area: drawer;
    position: sticky;
    align-self: flex-start;
    height: calc(100vh - $uds-navbar-height);
    &.--top-bar-displayed {
      height: calc(100vh - 114px);
    }
  }

  .content {
    grid-area: content;
  }
}

.default_layout_container.--toggle-drawer:has(.drawer) {
  grid-template-columns: 80px 2fr 2fr;
}
</style>
