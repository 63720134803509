<template>
  <div class="first-step-card">
    <!-- HEADER -->
    <header class="first-step-title">
      <div v-if="isCompleted" class="uds-paragraph text-neutral">
        🎉 Vous avez fini les Premiers pas !
      </div>
      <div v-else class="uds-paragraph text-neutral">Premiers pas sur l'application</div>
      <div class="fsc-progress-circle" :style="styleCircleWithData">
        <div class="fsc-pc-text">{{ completedRate }}%</div>
      </div>
    </header>

    <!-- CONTENT -->
    <div>
      <uds-expansion-card
        v-for="step in stepsFormatted"
        :key="`steps-${step.id}`"
        :disabled="step.done"
        :no-disabled-design="step.done"
      >
        <template #title>
          <div style="display: flex; width: 100%">
            <div :class="`step-title ${step.done ? '--done' : ''}`">
              <uds-icon
                :icon-name="step.done ? 'done' : step.icon"
                size="medium"
                class="text-neutral"
              />
              {{ step.title }}
            </div>
          </div>
        </template>
        <template #content>
          <div class="step-content">
            <div class="uds-paragraph --small">{{ step.description }}</div>
            <uds-main-button
              size="small"
              style="margin-top: 10px"
              @click="executeFirstStepAction(step as Step)"
            >
              Commencer
            </uds-main-button>
          </div>
        </template>
      </uds-expansion-card>
    </div>

    <!-- FOOTER -->
    <footer class="first-step-footer uds-paragraph --small" @click="cancelFirstStep()">
      <uds-icon icon-name="visibility_off" size="small" />Masquer les premiers pas
    </footer>
  </div>
</template>

<script setup lang="ts">
import { useModal } from "vue-final-modal";
import CustomAccountingPlan from "~/components/commons/modals/CustomAccountingPlan.vue";

const emit = defineEmits(["close"]);

const props = defineProps<{
  completedRate: number;
}>();

const udsColors = getUdsColors();
const primaryColors = computed(() => getPrimaryColors());

const isCompleted = computed(() => props.completedRate === 100);

const styleCircleWithData = computed(() => {
  return `background-image: radial-gradient(closest-side, white 90%, transparent 95% 100%), conic-gradient(${primaryColors.value.primary500} ${props.completedRate}%, ${udsColors.udsNeutral400} 0)`;
});

const { isComptalib, getBrandName } = useBrandsComposable();
const societyStore = useSocietyStore();
function cancelFirstStep() {
  try {
    $silex()
      .society.updateSociety(societyStore.society!.id!, {
        canceledFirstStepTourAt: new Date(),
      })
      .then(() => societyStore.fetchSociety(societyStore.society!.id!));
    emit("close");
  } catch (error) {
    $notifier().open({ type: "error", content: apiErrorToString(error) });
  }
}

interface Step {
  id: string;
  title: string;
  icon: string;
  description: string;
  link?: string;
  action?: () => void;
  done: boolean;
}

const linkMobileApp = computed(() => {
  const brandName = getBrandName();
  switch (brandName) {
    case "Comptalib":
      return "https://www.comptalib.com/application-mobile/";
    case "Clementine":
      return "https://www.clementine.fr/app/application-mobile/";
    case "Pourcentage":
      return "https://www.pourcentage.fr/application/?utm_source=google&utm_medium=cpc&utm_campaign=Pourcentage-SN-Marque";
    case "Comptastart":
      return "https://apps.apple.com/fr/app/comptastart/id1505541621";
    default:
      return "";
  }
});

const stepsFormatted = ref([
  {
    id: "syncBank",
    title: "Synchroniser un compte bancaire",
    description:
      "Récupérer quotidiennement vos opérations bancaires afin de réaliser votre comptabilité en un instant.",
    icon: "account_balance",
    link: "/settings/banks",
    done: isDefined(societyStore.firstSteps?.hasBankAccountSynchronized),
  },
  ...(isComptalib()
    ? [
        {
          id: "hasPersonnalizedChartOfAccounts",
          title: "Personnaliser son plan comptable",
          description:
            "Répondez à quelques questions au sujet de votre société pour adapter le plan comptable à votre activité.",
          icon: "format_list_bulleted",
          action: () => openAccountPlanModale(),
          done: isDefined(societyStore.firstSteps?.hasPersonnalizedChartOfAccounts),
        },
      ]
    : []),
  {
    id: "societyInfos",
    title: "Remplir ses coordonnées",
    description: `Compléter l'ensemble de vos coordonnées afin de pouvoir profiter pleinement de l'application.`,
    icon: "person",
    link: "/settings/informations",
    done: isDefined(societyStore.firstSteps?.hasContactInformationsFilled),
  },
  {
    id: "annotateBank",
    title: "Annoter une opération bancaire",
    description: `Annoter votre première opération bancaire en lui attribuant ${
      societyStore.isCashBased
        ? "une catégorie et un taux de TVA si nécessaire."
        : "un tiers, un client ou un fournisseur lui correspondant. S’il n’est pas dans la liste proposée, vous pouvez le créer."
    }`,
    icon: "euro",
    link: "/banks?toAnnotate=true",
    done: isDefined(societyStore.firstSteps?.hasAccountingTransactionAnnotated),
  },
  {
    id: "billing",
    title: "Configurer son outil de facturation",
    description:
      "Paramétrer le style et les mentions légales obligatoires pour pouvoir réaliser vos premières factures ou devis en quelques clics.",
    icon: "description",
    link: "/settings/billing",
    done: isDefined(societyStore.firstSteps?.hasBillingToolCustom),
  },
  {
    id: "payment",
    title: "Configurer le paiement en ligne",
    description:
      "Envoyez des liens de paiements à vos clients via notre outil de facturation ou directement par mail afin de vous faire payer plus rapidement et de manière sécurisée.",
    icon: "payment",
    link: "/settings/billing",
    done: isDefined(societyStore.firstSteps?.hasStripeConnector),
  },
  {
    id: "email",
    title: "Activer son mail personnalisé",
    description:
      "Une adresse email dédiée à votre comptabilité pour pouvoir transférer facilement vos factures d’achats sur ce mail et les retrouver dans l’onglet “Achats” du logiciel.",
    icon: "email",
    link: "/settings/purchase-mail",
    done: isDefined(societyStore.firstSteps?.hasEmailCustom),
  },
  {
    id: "mobileApp",
    title: "Télécharger l’application mobile",
    description:
      "Gérez et visualisez votre comptabilité de n’importe où grâce à notre application mobile.",
    icon: "phone_iphone",
    link: linkMobileApp.value,
    done: isDefined(societyStore.firstSteps?.hasMobileApp),
  },
]);

function openAccountPlanModale() {
  const { open, close } = useModal({
    component: CustomAccountingPlan,
    attrs: {
      onClose() {
        close();
      },
      onCancel() {
        close();
      },
    },
  });
  open();
  emit("close");
}

async function executeFirstStepAction(step: Step) {
  if (["mobileApp", "billing", "syncBank"].includes(step.id)) {
    let payload = {};
    switch (step.id) {
      case "mobileApp":
        payload = {
          hasMobileApp: new Date(),
        };
        break;
      case "billing":
        payload = {
          hasBillingToolCustom: new Date(),
        };
        break;
      case "syncBank":
        payload = {
          hasBankAccountSynchronized: new Date(),
        };
        break;
    }
    $silex()
      .firstStep.updateOrCreate(payload)
      .then(() => {
        societyStore.fetchOnboardingFirstStep();
      });
  }

  if (step.link) {
    if (step.link.includes("https")) {
      await navigateTo(step.link, { external: true, open: { target: "_blank" } });
    } else {
      await navigateTo(step.link);
    }
  } else if (step.action) {
    step.action();
  }
  emit("close");
}
</script>

<style lang="scss" scoped>
.text-neutral {
  color: $uds-neutral-800;
}

.first-step-card {
  border-radius: 7px;
  background-color: $uds-white;

  .first-step-title {
    padding: 0 $uds-spacing-3;
    min-height: 66px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid $uds-neutral-400;
  }

  .fsc-progress-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    .fsc-pc-text {
      color: $uds-primary-500;
      font-size: 18px;
    }
  }

  .first-step-footer {
    cursor: pointer;
    padding: 15px;
    margin-bottom: 0;
    color: $uds-neutral-800;
    display: flex;
    gap: $uds-spacing-1;
    align-items: center;
    justify-content: center;
    &:hover {
      opacity: 0.8;
    }
  }
}

:deep(.uds-shadow-xs) {
  box-shadow: none;
  border: none;
}

:deep(.uds-expansion-card) {
  border-bottom: 1px solid $uds-neutral-400;
  border-radius: 0;
  width: 420px;
  margin: 0;
}

.step-title {
  display: flex;
  place-items: center;
  gap: $uds-spacing-1;
  margin-right: 50px;

  :deep(.mdi-container) {
    span {
      background-color: $uds-neutral-100;
      padding: 5px;
      border-radius: 50px;
    }
  }

  &.--done {
    color: $uds-success-500;

    :deep(.mdi-container) {
      span {
        background-color: $uds-success-50;
        color: $uds-success-500;
      }
    }
  }
}

:deep(.ec-header) {
  padding: 10px $uds-spacing-3;
}

:deep(.ec-card) {
  background-color: $uds-neutral-100;
}
</style>
