<template>
  <uds-simple-dropdown ref="dropdown">
    <template #inputContent>
      <div class="first-step-button" :class="{ '--comptalib': isComptalib() }">
        Premiers pas
        <div class="fsb-bar" />
        <div class="fsb-progress-circle" :style="{ backgroundImage: progressCircleBG }" />
        {{ completedRate }}%
      </div>
    </template>

    <template #dropdownContent>
      <div v-if="pending" class="item-center">
        <CommonsSimpleLinePlaceholder />
        <div class="uds-paragraph text-neutral">Chargement...</div>
      </div>
      <NavbarFirstStepMenu v-else :completed-rate="completedRate" @close="closeDropdown" />
    </template>
  </uds-simple-dropdown>
</template>

<script setup lang="ts">
import type { IFirstStep } from "@silexpert/core";

const { isComptalib } = useBrandsComposable();
const societyStore = useSocietyStore();
const userStore = useUsersStore();

const { pending } = await useAsyncData(`first-steps-${societyStore.society?.id}`, async () => {
  await societyStore.fetchOnboardingFirstStep();
});

const primaryColors = computed(() => {
  return getPrimaryColors();
});

const dropdown = ref(null);
function closeDropdown() {
  // @ts-expect-error
  dropdown.value.isMenuShown = false;
}

onMounted(() => {
  if (!isDefined(userStore.user?.lastConnection)) {
    // @ts-expect-error
    dropdown.value.isMenuShown = true;
  }
});

type StepsType = keyof IFirstStep;
const firstStepFields = computed<StepsType[]>(() => {
  const steps: StepsType[] = [
    "hasBankAccountSynchronized",
    "hasAccountingTransactionAnnotated",
    "hasContactInformationsFilled",
    "hasEmailCustom",
    "hasStripeConnector",
    "hasMobileApp",
    "hasBillingToolCustom",
  ];

  if (isComptalib()) {
    steps.push("hasPersonnalizedChartOfAccounts");
  }

  return steps;
});

const completedRate = computed<number>(() => {
  if (!isDefined(societyStore.firstSteps)) {
    return 0;
  }
  let total = 0;
  firstStepFields.value.forEach((field) => {
    if (isDefined(societyStore.firstSteps) && isDefined(societyStore.firstSteps[field])) {
      total++;
    }
  });
  return roundAtXDecimals((total * 100) / firstStepFields.value.length, 0);
});

const progressCircleBG = computed(() => {
  return `radial-gradient(closest-side, ${
    isComptalib() ? "rgb(90, 53, 224)" : primaryColors.value.primary500
  } 70%, transparent 80% 100%), conic-gradient(white ${
    completedRate.value
  }%, rgba(255, 255, 255, 0.5) 0)`;
});
</script>

<style lang="scss" scoped>
.text-neutral {
  color: $uds-neutral-800;
  margin-top: -10px;
}

.item-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 $uds-spacing-2 $uds-spacing-2 $uds-spacing-2;
}

.first-step-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;

  transition: background-color 0.3s;
  border-radius: $uds-spacing-4;

  padding: 0 $uds-spacing-1_5;
  max-height: 32px;

  font-size: 16px;
  font-weight: $uds-weight-500;
  color: $uds-white;
  box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.64);

  background: $uds-primary-500;

  &.--comptalib {
    background: linear-gradient(
      -91.55deg,
      rgb(196, 1, 174) 1.34%,
      rgb(100, 48, 219) 23.62%,
      rgb(22, 86, 255) 80.47%
    );
  }

  .fsb-bar {
    margin: 0 $uds-spacing-1;
    background-color: $uds-white;
    width: 1px;
    height: 32px;
  }

  .fsb-progress-circle {
    margin-right: $uds-spacing-1;
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }

  &:hover {
    opacity: 0.7;
  }
}
</style>
