<template>
  <uds-tooltip tooltip-text="Parrainer un ami">
    <uds-action-button
      class="sponsor-button"
      icon-name="featured_seasonal_and_gifts"
      @click="
        navigateTo('https://www.clementine.fr/parrainage/', {
          external: true,
          open: { target: '_blank' },
        })
      "
    />
  </uds-tooltip>
</template>

<style scoped lang="scss">
.sponsor-button {
  margin-top: $uds-spacing-0_5;
}
</style>
