<template>
  <div>
    <uds-simple-dropdown ref="dropdown" placement="bottom">
      <template #inputContent>
        <div class="help-button" :class="{ active: helpPopupIsOpen }">
          <uds-icon icon-name="help" :color="color" class="icon" />
          <span class="help-text">Aide</span>
        </div>
      </template>
      <template #dropdownContent>
        <NavbarHelpMenu />
      </template>
    </uds-simple-dropdown>
  </div>
</template>

<script setup lang="ts">
const dropdown = ref(null);
// @ts-expect-error
const helpPopupIsOpen = computed(() => dropdown.value?.isMenuShown);

const color = computed(() =>
  helpPopupIsOpen.value ? getPrimaryColors().primary500 : getUdsColors().udsNeutral800,
);
</script>

<style lang="scss" scoped>
.help-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  padding: 10px $uds-spacing-2;
  border-radius: $uds-spacing-4;

  &:hover:not(.active) {
    background-color: $uds-neutral-300;
  }
  &.active {
    background-color: $uds-primary-50;

    .help-text {
      color: $uds-primary-500 !important;
    }
  }
}

.help-text {
  margin-left: 10px;
  font-size: 16px;
  font-family: $default-font-family;
  font-weight: $uds-weight-450;
  color: $uds-neutral-800;
}

:deep(.icon) {
  .material-symbols-rounded {
    font-size: 16px !important;
  }
}
</style>
