<template>
  <div
    class="setting-button"
    :class="{ active: isActive }"
    @click="navigateTo('/settings/informations')"
  >
    <uds-icon icon-name="settings" class="icon" :color="color" />
    <span class="setting-text">Paramètres</span>
  </div>
</template>

<script setup lang="ts">
const route = useRoute();
const udsColors = getUdsColors();
const primaryColors = getPrimaryColors();

const isActive = computed(() => route.fullPath?.includes("/settings"));
const color = computed(() => (isActive.value ? primaryColors.primary500 : udsColors.udsNeutral800));
</script>

<style lang="scss" scoped>
.setting-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 40px;
  transition: background-color 0.3s;
  padding: 0px $uds-spacing-2;
  border-radius: $uds-spacing-4;

  &:hover:not(.active) {
    background-color: $uds-neutral-300;
  }
  &.active {
    background-color: $uds-primary-50;

    .setting-text {
      color: $uds-primary-500 !important;
    }
  }
}

.setting-text {
  margin-left: 10px;
  font-size: 16px;
  font-family: $default-font-family;
  font-weight: $uds-weight-450;
  color: $uds-neutral-800;
}

:deep(.icon) {
  .material-symbols-rounded {
    font-size: 16px !important;
  }
}
</style>
