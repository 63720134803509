<template>
  <div/>
</template>

<script setup lang="ts">
import { useModal } from "vue-final-modal";
import UnpaidModal from "~/components/commons/modals/UnpaidModal.vue";

const societyStore = useSocietyStore();

const isDialogOpened = computed(() => societyStore.shouldDisplayUnpaidDialog);

watch(
  () => isDialogOpened.value,
  () => (isDialogOpened.value ? show() : null),
);

onMounted(() => (isDialogOpened.value ? show() : null));

async function show() {
  const { open, close } = useModal({
    component: UnpaidModal,
    attrs: {
      svg: "WomanQuestionSvg",
      onCancel() {
        close();
      },
      onClose() {
        close();
      },
    },
  });
  await open();
}
</script>
